import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  MenuItem,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import ErrorIcon from 'assets/icons/error_icon.svg'

type MenuActionItemProps = {
  confirmDialogTitle: string
  confirmDialogContent: string
  confirmButtonText: string
  onConfirm: () => Promise<void>
  children: React.ReactNode
}

const MenuActionItem: React.FC<MenuActionItemProps> = ({
  confirmDialogTitle,
  confirmDialogContent,
  onConfirm,
  confirmButtonText,
  children,
}) => {
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false)

  const [isSaving, setIsSaving] = useState(false)

  const handleConfirm = async (): Promise<void> => {
    setIsSaving(true)
    await onConfirm()
    setIsSaving(false)
    setOpen(false)
  }

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {children}
      </MenuItem>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">{confirmDialogTitle}</Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          <DialogContentText id="alert-dialog-description">
            {confirmDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <LoadingButton
              fullWidth
              loading={isSaving}
              onClick={() => {
                void handleConfirm()
              }}
              autoFocus
              variant="contained"
              color="secondary"
            >
              {confirmButtonText}
            </LoadingButton>
            <Button
              fullWidth
              onClick={() => {
                setOpen(false)
              }}
              variant="outlined"
              color="secondary"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MenuActionItem
