import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Stack,
  CircularProgress,
  Box,
  Typography,
  Button,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import WebsiteIcon from '@mui/icons-material/Language'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import AddressIcon from '@mui/icons-material/LocationOn'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import {
  MainHeader,
  StrongText,
  SubContentWrapper,
  SubHeader,
} from 'components/StyledComponents'
import EmptyItemIcon from 'assets/icons/empty_item.svg'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  BUSINESS_SEGMENT_ICONS,
  BUSINESS_SEGMENT_LABELS,
  ContactDetailType,
} from 'components/item/itemConstants'
import { type Item } from 'components/item/itemTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import MenuActionItem from 'components/helpers/MenuActionItem'
import { useSnackbar } from 'notistack'
import useApi from 'hooks/useApi'

const ItemWrapper = styled(Stack)<{ disabled?: boolean }>`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.action.disabled : 'inherit'};

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .sub-content {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`

const SegmentWrapper = styled(Stack)`
  svg {
    height: 18px;
    width: 18px;
  }
`

const ItemListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent, formatPhoneNumber } = usePortalSetting()
  const {
    data: itemsData,
    isLoading,
    mutate: mutateItems,
  } = useSWR<Item[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items/basic`
      : null,
  )
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [selectedItem, setSelectedItem] = useState<Item | null>(null)
  const { sendPostRequest } = useApi()

  const handleClick = (itemId: string): void => {
    goTo(`${Path.ITEMS_EDIT}/${itemId}`)
  }

  const handleEditBasicInfo = (): void => {
    goTo(`${Path.ITEMS_EDIT}/${selectedItem?.id}`)
  }

  const handleEditAdvancedSettings = (): void => {
    goTo(`${Path.ITEMS_ADVANCED_EDIT}/${selectedItem?.id}`)
  }

  const sortedItems = useMemo(
    () =>
      itemsData?.sort((a, b) => {
        const nameA = getLocalizedContent(a.names).toUpperCase()
        const nameB = getLocalizedContent(b.names).toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [itemsData],
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    item: Item,
  ): void => {
    event.stopPropagation()
    setMenuEl(event.currentTarget)
    setSelectedItem(item)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const deactivateItem = useCallback(async (): Promise<void> => {
    handleCloseMenu()

    if (!portalSetting || !selectedItem) {
      return
    }

    try {
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/items/${selectedItem.id}:deactivate`,
      )

      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateItems()
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting, selectedItem])

  const activateItem = useCallback(async (): Promise<void> => {
    handleCloseMenu()

    if (!portalSetting || !selectedItem) {
      return
    }

    try {
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/items/${selectedItem.id}:activate`,
      )

      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateItems()
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting, selectedItem])

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'portal_item_list.header' })}
          </MainHeader>
        </Box>

        {sortedItems &&
          sortedItems?.length > 0 &&
          checkAccesses({
            [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.ITEMS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({ id: 'portal_item_list.button.create_item' })}
            </Button>
          )}
      </Stack>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {!sortedItems ||
          (sortedItems.length === 0 && (
            <ItemWrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <CircularProgress />}

              {!isLoading &&
                checkAccesses({
                  [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
                }) && (
                  <Stack textAlign="center" spacing={2} alignItems="center">
                    <EmptyItemIcon />
                    <Box width="100%">
                      <SubHeader>
                        {formatMessage({
                          id: 'portal_item_list.label.start_creating_item',
                        })}
                      </SubHeader>
                    </Box>
                    <Box width="100%">
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          goTo(Path.ITEMS_ADD)
                        }}
                        color="secondary"
                      >
                        {formatMessage({
                          id: 'portal_item_list.button.create_item',
                        })}
                      </Button>
                    </Box>
                  </Stack>
                )}
            </ItemWrapper>
          ))}

        {sortedItems?.map((item) => {
          const IconComponent =
            !!item.businessSegment &&
            BUSINESS_SEGMENT_ICONS[item.businessSegment]
          return (
            <ItemWrapper
              key={item.id}
              direction="row"
              spacing={2}
              paddingX={4}
              paddingY={2}
              alignItems="center"
              disabled={!item.active}
              onClick={() => {
                if (
                  item.active &&
                  checkAccesses({
                    [PortalSection.ITEMS]: [FeatureAccess.WRITE],
                  })
                ) {
                  handleClick(item.id)
                }
              }}
            >
              <LogoWrapper
                size={120}
                url={item.logoUrl}
                alt={getLocalizedContent(item.names)}
              >
                {!item.logoUrl && (
                  <LightbulbIcon fontSize="small" sx={{ fontSize: 80 }} />
                )}
              </LogoWrapper>

              <Stack
                justifyContent="center"
                spacing={1}
                flex={1}
                minWidth={300}
              >
                <SubHeader>{getLocalizedContent(item.names)}</SubHeader>
                {IconComponent && item.businessSegment && (
                  <SegmentWrapper
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <IconComponent />

                    <Box>
                      {formatMessage({
                        id: BUSINESS_SEGMENT_LABELS[item.businessSegment],
                      })}
                    </Box>
                  </SegmentWrapper>
                )}
                <StrongText>{item.website}</StrongText>

                {(item.address || item.locationDetails?.location?.address) && (
                  <StrongText>
                    <address>
                      {item.address || item.locationDetails?.location?.address}
                    </address>
                  </StrongText>
                )}
              </Stack>

              <Stack flex={1.5} spacing={1} minWidth={300}>
                {portalSetting &&
                  item.contacts.map((contact, index) => (
                    <SubContentWrapper key={index} direction="row" spacing={1}>
                      <Stack spacing={1} flex={1}>
                        <StrongText>
                          {getLocalizedContent(contact.names)}
                        </StrongText>
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'pre-line' }}
                        >
                          {getLocalizedContent(contact.descriptions)}
                        </Typography>
                      </Stack>

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Stack flex={1} spacing={1}>
                        {contact.details.map((info, index) => (
                          <Stack
                            key={index}
                            direction="row"
                            spacing={1}
                            sx={{ whiteSpace: 'pre-line' }}
                          >
                            {info.type === ContactDetailType.PHONE && (
                              <PhoneIcon fontSize="small" />
                            )}
                            {info.type === ContactDetailType.EMAIL && (
                              <EmailIcon fontSize="small" />
                            )}
                            {info.type === ContactDetailType.WEBSITE && (
                              <WebsiteIcon fontSize="small" />
                            )}
                            <Typography variant="body2">
                              {info.type === ContactDetailType.PHONE
                                ? formatPhoneNumber(
                                    getLocalizedContent(info.texts),
                                  )
                                : getLocalizedContent(info.texts)}
                            </Typography>
                          </Stack>
                        ))}

                        {!!contact.addresses &&
                          contact.addresses.length > 0 && (
                            <Stack direction="row" spacing={1}>
                              <AddressIcon fontSize="small" />
                              <address>
                                <Typography variant="body2">
                                  {getLocalizedContent(contact.addresses)}
                                </Typography>
                              </address>
                            </Stack>
                          )}
                      </Stack>
                    </SubContentWrapper>
                  ))}
              </Stack>

              {((item.active &&
                checkAccesses({
                  [PortalSection.ITEMS]: [FeatureAccess.WRITE],
                })) ||
                (item.active &&
                  checkAccesses({
                    [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
                  })) ||
                (checkAccesses({
                  [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
                }) &&
                  item._operations?.canDeactivate) ||
                (checkAccesses({
                  [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
                }) &&
                  item._operations?.canActivate)) && (
                <Stack height="100%">
                  <IconButton
                    onClick={(event): void => {
                      handleOpenMenu(event, item)
                    }}
                    size="small"
                    aria-label={formatMessage({
                      id: 'general.icon_button.see_more',
                    })}
                  >
                    <MoreVertIcon
                      sx={{
                        fontSize: 16,
                      }}
                    />
                  </IconButton>
                </Stack>
              )}
            </ItemWrapper>
          )
        })}
      </Stack>

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        {selectedItem?.active &&
          checkAccesses({
            [PortalSection.ITEMS]: [FeatureAccess.WRITE],
          }) && (
            <MenuItem onClick={handleEditBasicInfo}>
              {formatMessage({
                id: 'portal_item_list.menu.edit_basic_info',
              })}
            </MenuItem>
          )}

        {selectedItem?.active &&
          checkAccesses({
            [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
          }) && (
            <MenuItem onClick={handleEditAdvancedSettings}>
              {formatMessage({
                id: 'portal_item_list.menu.edit_advanced_settings',
              })}
            </MenuItem>
          )}

        {checkAccesses({
          [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
        }) &&
          selectedItem?._operations?.canDeactivate && (
            <MenuActionItem
              confirmDialogTitle={formatMessage({
                id: 'portal_item_list.confirm.deactivate.title',
              })}
              confirmDialogContent={formatMessage({
                id: 'portal_item_list.confirm.deactivate.content',
              })}
              confirmButtonText={formatMessage({
                id: 'general.icon_button.deactivate',
              })}
              onConfirm={async (): Promise<void> => {
                await deactivateItem()
              }}
            >
              {formatMessage({
                id: 'portal_item_list.menu.deactivate',
              })}
            </MenuActionItem>
          )}

        {checkAccesses({
          [PortalSection.ITEMS_ADVANCED]: [FeatureAccess.WRITE],
        }) &&
          selectedItem?._operations?.canActivate && (
            <MenuActionItem
              confirmDialogTitle={formatMessage({
                id: 'portal_item_list.confirm.activate.title',
              })}
              confirmDialogContent={formatMessage({
                id: 'portal_item_list.confirm.activate.content',
              })}
              confirmButtonText={formatMessage({
                id: 'general.icon_button.activate',
              })}
              onConfirm={async (): Promise<void> => {
                await activateItem()
              }}
            >
              {formatMessage({
                id: 'portal_item_list.menu.activate',
              })}
            </MenuActionItem>
          )}
      </Menu>
    </Stack>
  )
}

export default ItemListPage
