import { atom } from 'recoil'
import { type CaseStatus, type Period } from 'components/case/caseConstants'
import {
  PUBLIC_CASE_PERIODS_DEFAULT,
  PUBLIC_CASE_STATUSES_DEFAULT,
} from 'components/publicCase/publicCaseConstants'
import { type PublicCaseInputData } from 'components/publicCase/publicCaseTypes'

const selectedPublicCaseStatusesState = atom<CaseStatus[]>({
  key: 'selectedPublicCaseStatusesState',
  default: PUBLIC_CASE_STATUSES_DEFAULT,
})

const selectedPublicCasePeriodState = atom<Period>({
  key: 'selectedPublicCasePeriodState',
  default: PUBLIC_CASE_PERIODS_DEFAULT,
})

const publicKeywordState = atom<string>({
  key: 'publicKeywordState',
  default: '',
})

const selectedPublicCaseCategoriesState = atom<string[]>({
  key: 'selectedPublicCaseCategoriesState',
  default: [],
})

const selectedPublicPortalItemsState = atom<string[]>({
  key: 'selectedPublicPortalItemsState',
  default: [],
})

const publicCaseFilterInputsState = atom<PublicCaseInputData | null>({
  key: 'publicCaseFilterInputsState',
  default: null,
})

export {
  selectedPublicCaseStatusesState,
  selectedPublicCasePeriodState,
  publicKeywordState,
  selectedPublicCaseCategoriesState,
  selectedPublicPortalItemsState,
  publicCaseFilterInputsState,
}
