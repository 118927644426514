import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { type Loader as GMapLoader } from '@googlemaps/js-api-loader'
import { useRecoilState } from 'recoil'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import styled from '@mui/material/styles/styled'
import { debounce } from '@mui/material/utils'
import {
  Stack,
  Box,
  CircularProgress,
  Button,
  Typography,
  Divider,
  CardHeader,
  Avatar,
} from '@mui/material'
import UpdateIcon from '@mui/icons-material/Update'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ImageIcon from '@mui/icons-material/Image'
import MessageIcon from '@mui/icons-material/Message'

import CaseMarker from 'components/case/CaseMarker'
import { type PublicCaseInfo } from './publicCaseTypes'
import { parseComponentToElement, parseSvgToElement } from 'utils/domUtils'
import {
  SmallInfoText,
  SubHeader,
  SubSubHeader,
} from 'components/StyledComponents'
import CaseStatusBadge from 'components/case/CaseStatusBadge'
import ResourceInlineSlider from 'components/resource/ResourceInlineSlider'
import { type CaseStatus } from 'components/case/caseConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import PublicCommentList from 'components/publicCase/PublicCommentList'
import EmptyResultIcon from 'assets/icons/empty_result.svg'
import { nameInitials } from 'utils/stringUtils'
import { AnonymousIcon } from 'components/icons/Icons'
import CategoryTagList from 'components/category/CategoryTagList'
import { getThumbnailUrl } from 'utils/fileUtils'
import { getGoogleMapsLoader } from 'components/form/mapLoader'
import { currentMapTypeState } from 'state/caseListStates'
import ZoomInIcon from 'assets/icons/map_zoom_in.svg'
import ZoomOutIcon from 'assets/icons/map_zoom_out.svg'
import SatelliteIcon from 'assets/icons/map_type_satellite.svg'
import RoadmapIcon from 'assets/icons/map_type_roadmap.svg'
import PublicCaseFilter from 'components/publicCase/PublicCaseFilter'

type CaseListMapProps = {
  portalId: string
  region?: string
  language?: string
  zoom?: number
  center?: google.maps.LatLngLiteral
  caseListWidth: number
  isLoading: boolean
  onRefresh: () => void
  cases: PublicCaseInfo[]
}

const CaseWrapper = styled(Stack)<{
  selected: boolean
}>`
  border: ${({ selected, theme }) =>
    `1px solid ${selected ? 'rgba(146, 160, 216, 1)' : theme.palette.divider}`};

  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 5px 1px rgba(7, 32, 141, 1)' : 'none'};

  background: ${({ selected, theme }) =>
    selected ? theme.palette.info.light : theme.palette.background.paper};

  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  cursor: pointer;
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  > .user-card {
    background: ${({ selected, theme }) =>
      selected ? theme.palette.background.paper : theme.palette.info.light};
  }
`

const EmptyCaseListWrapper = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  background: ${({ theme }) => theme.palette.background.paper};
`

const EmptyCaseIconWrapper = styled(Box)`
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(6)};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: rgba(245, 246, 254, 1);
`

const TitleWrapper = styled('h2')`
  font-size: 22px;
  font-weight: 500;
`

const ShortenTitleWrapper = styled(TitleWrapper)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
`

const AddressWrapper = styled(Stack)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  gap: 6px;
`

const CommentCounter = styled(Box)`
  padding: 2px 6px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: rgba(233, 236, 252, 1);
  font-size: 0.8rem;
`

const UserCard = styled(CardHeader)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const SenderName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

const REGION = 'FI'
const DEFAULT_ZOOM = 12
const DEFAULT_CENTER = { lat: 60.1695, lng: 24.9354 }
const DEFAULT_LANGUAGE = 'fi'

type FilteredCase = PublicCaseInfo & {
  marker: google.maps.marker.AdvancedMarkerElement
}

const PublicCaseListMap: React.FC<CaseListMapProps> = ({
  portalId,
  region = REGION,
  language = DEFAULT_LANGUAGE,
  zoom = DEFAULT_ZOOM,
  center = DEFAULT_CENTER,
  caseListWidth,
  isLoading,
  onRefresh,
  cases,
}) => {
  const { formatMessage } = useIntl()
  const caseListRef = useRef<HTMLDivElement>(null)
  const gmapLoaderRef = useRef<GMapLoader | null>(null)
  const mapElementRef = useRef<HTMLElement>(null)
  const mapServiceRef = useRef<google.maps.Map | null>(null)
  const markerClusterRef = useRef<MarkerClusterer | null>(null)
  const [filteredCases, setFilterCases] = useState<FilteredCase[]>([])
  const [isMapLoading, setIsMapLoading] = useState(false)
  const selectedMarkerRef =
    useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null)
  const [openedCaseId, setOpenedCaseId] = useState<string | null>(null)
  const { formatDate } = usePortalSetting()
  const [currentMapType, setCurrentMapType] =
    useRecoilState(currentMapTypeState)
  const casesRef = useRef(cases)

  const loadMarkers = async (): Promise<void> => {
    if (mapServiceRef.current && gmapLoaderRef.current) {
      const cases = casesRef.current
      const { AdvancedMarkerElement } =
        await gmapLoaderRef.current.importLibrary('marker')
      const newFilterCases: FilteredCase[] = []

      const results = cases.filter(
        (caseInfo) =>
          caseInfo.location?.position &&
          mapServiceRef.current
            ?.getBounds()
            ?.contains(caseInfo.location?.position),
      )

      const markers = results.map((caseInfo) => {
        const marker = new AdvancedMarkerElement({
          position: caseInfo.location?.position,
          title: caseInfo.title,
          content: parseComponentToElement(
            <CaseMarker
              status={caseInfo.status}
              aria-describedby={`case-${caseInfo.id}`}
            />,
          ),
        })

        marker.addListener('click', () => {
          if (selectedMarkerRef.current) {
            const container = selectedMarkerRef.current.content
              ?.firstChild as HTMLDivElement
            const status = container.getAttribute('status') as CaseStatus
            selectedMarkerRef.current.content = parseComponentToElement(
              <CaseMarker status={status} selected={false} />,
            )
          }

          selectedMarkerRef.current = marker
          marker.content = parseComponentToElement(
            <CaseMarker status={caseInfo.status} selected={true} />,
          )

          if (caseListRef.current) {
            const selectedCase = caseListRef.current.querySelector(
              `#case-${caseInfo.id}`,
            )

            if (selectedCase) {
              selectedCase.scrollIntoView({ behavior: 'smooth' })
            }

            setSelectedCaseId(caseInfo.id)
          }
        })

        newFilterCases.push({
          ...caseInfo,
          marker,
        })

        return marker
      })

      setFilterCases(newFilterCases)

      markerClusterRef.current?.clearMarkers()
      markerClusterRef.current?.addMarkers(markers)
      setIsMapLoading(false)
    }
  }

  const debounceLoadMarkers = debounce(async () => {
    await loadMarkers()
  }, 400)

  const mapControl = (
    controlDiv: HTMLDivElement,
    map: google.maps.Map,
  ): void => {
    controlDiv.style.marginRight = '16px'
    controlDiv.style.marginBottom = '16px'
    controlDiv.style.display = 'flex'
    controlDiv.style.flexDirection = 'column'
    controlDiv.style.gap = '8px'
    controlDiv.style.justifyContent = 'center'
    controlDiv.style.alignItems = 'center'

    const zoomInButton = document.createElement('div')
    zoomInButton.style.display = 'flex'
    zoomInButton.style.width = '26px'
    zoomInButton.style.height = '26px'
    zoomInButton.style.borderRadius = '4px'
    zoomInButton.style.backgroundColor = '#fff'
    zoomInButton.style.justifyContent = 'center'
    zoomInButton.style.alignItems = 'center'
    zoomInButton.style.cursor = 'pointer'
    zoomInButton.appendChild(parseSvgToElement(<ZoomInIcon />))
    controlDiv.appendChild(zoomInButton)

    const zoomOutButton = document.createElement('div')
    zoomOutButton.style.display = 'flex'
    zoomOutButton.style.width = '26px'
    zoomOutButton.style.height = '26px'
    zoomOutButton.style.borderRadius = '4px'
    zoomOutButton.style.backgroundColor = '#fff'
    zoomOutButton.style.justifyContent = 'center'
    zoomOutButton.style.alignItems = 'center'
    zoomOutButton.style.cursor = 'pointer'
    zoomOutButton.appendChild(parseSvgToElement(<ZoomOutIcon />))
    controlDiv.appendChild(zoomOutButton)

    const satelliteButton = document.createElement('div')
    satelliteButton.style.display =
      map.getMapTypeId() === google.maps.MapTypeId.HYBRID ? 'none' : 'flex'
    satelliteButton.style.width = '26px'
    satelliteButton.style.height = '26px'
    satelliteButton.style.borderRadius = '4px'
    satelliteButton.style.backgroundColor = '#fff'
    satelliteButton.style.justifyContent = 'center'
    satelliteButton.style.alignItems = 'center'
    satelliteButton.style.cursor = 'pointer'
    satelliteButton.appendChild(parseSvgToElement(<SatelliteIcon />))
    controlDiv.appendChild(satelliteButton)

    const roadmapButton = document.createElement('div')
    roadmapButton.style.display =
      map.getMapTypeId() === google.maps.MapTypeId.HYBRID ? 'flex' : 'none'
    roadmapButton.style.width = '26px'
    roadmapButton.style.height = '26px'
    roadmapButton.style.borderRadius = '4px'
    roadmapButton.style.backgroundColor = '#fff'
    roadmapButton.style.justifyContent = 'center'
    roadmapButton.style.alignItems = 'center'
    roadmapButton.style.cursor = 'pointer'
    roadmapButton.appendChild(parseSvgToElement(<RoadmapIcon />))
    controlDiv.appendChild(roadmapButton)

    satelliteButton.addEventListener('click', () => {
      satelliteButton.style.display = 'none'
      roadmapButton.style.display = 'flex'
      map.setMapTypeId(google.maps.MapTypeId.HYBRID)
    })

    roadmapButton.addEventListener('click', () => {
      roadmapButton.style.display = 'none'
      satelliteButton.style.display = 'flex'
      map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
    })

    zoomInButton.addEventListener('click', () => {
      map.setZoom((map.getZoom() ?? DEFAULT_ZOOM) + 1)
    })

    zoomOutButton.addEventListener('click', () => {
      map.setZoom((map.getZoom() ?? DEFAULT_ZOOM) - 1)
    })
  }

  const init = async (): Promise<void> => {
    if (mapElementRef.current) {
      setIsMapLoading(true)
      gmapLoaderRef.current = getGoogleMapsLoader({
        region,
        language,
      })

      const { Map } = await gmapLoaderRef.current.importLibrary('maps')

      let defaultCenter = center

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          defaultCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
        })
      }

      mapServiceRef.current = new Map(mapElementRef.current, {
        center: defaultCenter,
        zoom,
        mapId: process.env.REACT_APP_GMAP_ID,
        mapTypeId: currentMapType,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        streetView: null,
      })

      const mapControlDiv = document.createElement('div')
      mapControl(mapControlDiv, mapServiceRef.current)

      mapServiceRef.current.controls[
        google.maps.ControlPosition.RIGHT_BOTTOM
      ].push(mapControlDiv)

      mapServiceRef.current.addListener(
        'zoom_changed',
        async (): Promise<void> => {
          await debounceLoadMarkers()
        },
      )

      mapServiceRef.current.addListener('dragend', async (): Promise<void> => {
        await debounceLoadMarkers()
      })

      markerClusterRef.current = new MarkerClusterer({
        markers: [],
        map: mapServiceRef.current,
      })

      mapServiceRef.current.addListener(
        'tilesloaded',
        async (): Promise<void> => {
          await loadMarkers()
        },
      )

      mapServiceRef.current.addListener('maptypeid_changed', () => {
        const currentMapTypeId = mapServiceRef.current?.getMapTypeId()
        if (currentMapTypeId) {
          setCurrentMapType(currentMapTypeId)
          localStorage.setItem('mapTypeId', currentMapTypeId)
        }
      })
    }
  }

  useEffect(() => {
    casesRef.current = cases
    if (cases.length > 0) {
      if (mapServiceRef.current) {
        void loadMarkers()
      } else {
        void init()
        const analytics = getAnalytics()
        logEvent(analytics, 'web_public_cases_loaded')
      }
    } else {
      setFilterCases([])
      if (markerClusterRef.current) {
        markerClusterRef.current.clearMarkers()
      }
    }
  }, [cases])

  const handleMouseEnterCase = (filteredCase: FilteredCase): void => {
    if (selectedMarkerRef.current) {
      const container = selectedMarkerRef.current.content
        ?.firstChild as HTMLDivElement
      if (container) {
        const status = container.getAttribute('status') as CaseStatus
        selectedMarkerRef.current.content = parseComponentToElement(
          <CaseMarker status={status} selected={false} />,
        )
      }
    }

    filteredCase.marker.content = parseComponentToElement(
      <CaseMarker status={filteredCase.status} selected={true} />,
    )

    setSelectedCaseId(filteredCase.id)
  }

  const handleMouseLeaveCase = (filteredCase: FilteredCase): void => {
    filteredCase.marker.content = parseComponentToElement(
      <CaseMarker status={filteredCase.status} selected={false} />,
    )

    setSelectedCaseId(null)
  }

  const handleRowClick = useCallback((filteredCase: FilteredCase): void => {
    setOpenedCaseId(filteredCase.id)
  }, [])

  return (
    <>
      <Stack width={'100%'} height={'100%'} spacing={2}>
        <PublicCaseFilter portalId={portalId} />
        <Stack
          flex={1}
          width={'100%'}
          direction="row"
          spacing={2}
          overflow="hidden"
        >
          <Stack
            ref={caseListRef}
            overflow="auto"
            spacing={1}
            width={`${caseListWidth}px`}
            padding={0.5}
          >
            {filteredCases.map((filteredCase) => (
              <CaseWrapper
                key={filteredCase.id}
                id={`case-${filteredCase.id}`}
                width={'100%'}
                onMouseEnter={() => {
                  handleMouseEnterCase(filteredCase)
                }}
                onMouseLeave={() => {
                  handleMouseLeaveCase(filteredCase)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                }}
                spacing={1}
                onClick={() => {
                  handleRowClick(filteredCase)
                }}
                selected={selectedCaseId === filteredCase.id}
                tabIndex={0}
              >
                <Stack direction="row" width={'100%'} paddingX={1}>
                  <SmallInfoText flexGrow={1} paddingTop={0.5}>
                    <UpdateIcon sx={{ fontSize: 14 }} />
                    {formatDate(filteredCase.updated)}
                  </SmallInfoText>

                  <Stack direction="row" spacing={2}>
                    {filteredCase.resources.length > 0 && (
                      <SmallInfoText>
                        <ImageIcon sx={{ fontSize: 16 }} />
                        {filteredCase.resources.length}
                      </SmallInfoText>
                    )}

                    {filteredCase.comments.length > 0 && (
                      <SmallInfoText>
                        <MessageIcon sx={{ fontSize: 16 }} />
                        {filteredCase.comments.length}
                      </SmallInfoText>
                    )}

                    <CaseStatusBadge status={filteredCase.status} />
                  </Stack>
                </Stack>

                <Stack paddingX={1} spacing={1}>
                  {openedCaseId === filteredCase.id &&
                    filteredCase.resources.length > 0 && (
                      <ResourceInlineSlider
                        resources={filteredCase.resources}
                        width={caseListWidth - 26}
                        height={
                          openedCaseId === filteredCase.id
                            ? caseListWidth * 0.7
                            : caseListWidth * 0.6
                        }
                        showZoomInButton={true}
                        imageSize="cover"
                      />
                    )}

                  {openedCaseId !== filteredCase.id && (
                    <ShortenTitleWrapper>
                      {filteredCase.title || filteredCase.description}
                    </ShortenTitleWrapper>
                  )}

                  {openedCaseId === filteredCase.id && (
                    <TitleWrapper>
                      {filteredCase.title || filteredCase.description}
                    </TitleWrapper>
                  )}

                  <Stack spacing={1} paddingBottom={2}>
                    <SmallInfoText flexGrow={1} paddingBottom={2}>
                      {formatMessage(
                        {
                          id: 'case_detail.label.report_created',
                        },
                        {
                          date: formatDate(filteredCase.created),
                        },
                      )}
                    </SmallInfoText>

                    <AddressWrapper direction="row">
                      <LocationOnIcon fontSize="small" color="secondary" />
                      {filteredCase.location?.address}
                    </AddressWrapper>

                    {!filteredCase.category?.uncategorized && (
                      <Box overflow="hidden" paddingLeft={2.5}>
                        <CategoryTagList category={filteredCase.category} />
                      </Box>
                    )}
                  </Stack>

                  {openedCaseId === filteredCase.id && (
                    <Stack spacing={2}>
                      <Typography variant="body2">
                        {filteredCase.description}
                      </Typography>

                      <SmallInfoText flexGrow={1} marginBottom={1}>
                        {formatMessage(
                          {
                            id: 'public_case.noticed',
                          },
                          {
                            date: formatDate(filteredCase.occurred),
                          },
                        )}
                      </SmallInfoText>

                      {filteredCase.reporter && (
                        <UserCard
                          avatar={
                            <Avatar
                              src={getThumbnailUrl(
                                filteredCase.reporter?.avatarUrl,
                              )}
                              sx={{ width: 30, height: 30 }}
                              alt={filteredCase.reporter.name}
                            >
                              {nameInitials(filteredCase.reporter.name)}
                            </Avatar>
                          }
                          title={
                            <Stack>
                              <SmallInfoText>
                                {formatMessage({
                                  id: 'case_list.cases.header.reported_by',
                                })}
                              </SmallInfoText>
                              <SenderName>
                                {filteredCase.reporter.name}
                              </SenderName>
                            </Stack>
                          }
                        />
                      )}

                      {!filteredCase.reporter && (
                        <UserCard
                          avatar={
                            <Avatar
                              sx={{ width: 30, height: 30 }}
                              alt={formatMessage({
                                id: 'case_detail.label.anonymous',
                              })}
                            >
                              <AnonymousIcon />
                            </Avatar>
                          }
                          title={
                            <Stack>
                              <SmallInfoText>
                                {formatMessage({
                                  id: 'case_list.cases.header.reported_by',
                                })}
                              </SmallInfoText>
                              <SenderName>
                                {formatMessage({
                                  id: 'case_detail.label.anonymous',
                                })}
                              </SenderName>
                            </Stack>
                          }
                        />
                      )}

                      <Divider />

                      <Stack direction="row" spacing={1} alignItems="center">
                        <SubSubHeader>
                          {formatMessage({
                            id: 'case_detail.tab.label.comments',
                          })}
                        </SubSubHeader>

                        {filteredCase.comments.length > 0 && (
                          <CommentCounter>
                            {filteredCase.comments.length}
                          </CommentCounter>
                        )}
                      </Stack>

                      <PublicCommentList comments={filteredCase.comments} />
                    </Stack>
                  )}
                </Stack>
              </CaseWrapper>
            ))}

            {!isMapLoading && filteredCases.length === 0 && (
              <EmptyCaseListWrapper spacing={2}>
                {isLoading && <CircularProgress />}

                {!isLoading && (
                  <Stack spacing={2}>
                    <EmptyCaseIconWrapper>
                      <EmptyResultIcon />
                    </EmptyCaseIconWrapper>
                    <SubHeader>
                      {formatMessage({
                        id: 'case_list_map.label.case_empty',
                      })}
                    </SubHeader>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({
                          id: 'case_list.text.no_map_result',
                        }),
                      }}
                    ></div>

                    <Box>
                      <Button
                        size="small"
                        onClick={onRefresh}
                        variant="outlined"
                      >
                        {formatMessage({ id: 'general.button.refresh' })}
                      </Button>
                    </Box>
                  </Stack>
                )}
              </EmptyCaseListWrapper>
            )}
          </Stack>

          <Box
            ref={mapElementRef}
            flexGrow={1}
            height="100%"
            borderRadius={1}
          ></Box>
        </Stack>
      </Stack>
    </>
  )
}

export default PublicCaseListMap
