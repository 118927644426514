import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import {
  Stack,
  MenuItem,
  Select,
  SwipeableDrawer,
  Link,
  Typography,
  IconButton,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd'
import MenuIcon from '@mui/icons-material/Menu'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'
import SearchIcon from '@mui/icons-material/Search'

import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import {
  Path,
  PortalType,
  PREPORTI_SUPPORT_LOCALES_MAP,
  SUPPORT_LOCALE_LABEL,
  ThemeMode,
} from '../../commonConstants'
import { type Locale } from 'types'
import useRoute from 'hooks/useNavigate'
import EportiLogo from 'assets/icons/eporti_white_logo.svg'
import PublicDownloadAppLinks from 'components/publicCase/PublicDownloadAppLinks'
import { themeModeState } from 'state/layoutStates'
import { PublicView } from 'components/publicCase/PublicTopBanner'

type PublicTopBannerMobileProps = {
  currentView: PublicView
  onChangeView: (event: React.SyntheticEvent, newView: PublicView) => void
}

const TopBannerWrapper = styled(Stack)`
  box-shadow: 0px 4px 8px 0px rgba(35, 45, 66, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
`

const LogoWrapper = styled(Stack)`
  & img {
    cursor: pointer;
  }
`

const TabsWrapper = styled(Stack)`
  height: 100%;
  flex-direction: column-reverse;
`

const Tabs = styled(MuiTabs)`
  min-height: 44px;
  width: 100%;
  display: flex;
`

const Tab = styled(MuiTab)`
  padding: 10px 16px;
  flex: 1;
  min-height: 0;
  color: inherit;
  font-weight: 500;
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.error.main};
    background: ${({ theme }) =>
      theme.palette.mode === 'dark' ? theme.palette.common.black : '#f3f3f7'};
  }
`

const MenuDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    background: #000621;
    color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme }) => theme.spacing(2)};
    width: 260px;
  }

  .MuiLink-root {
    color: ${({ theme }) => theme.palette.common.white};
    font-weight: 500;
  }

  .MuiSelect-select {
    background: ${({ theme }) => theme.palette.background.paper};
  }
`

const PortalTitle = styled('h1')`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.common.white};
`

const PublicTopBannerMobile: React.FC<PublicTopBannerMobileProps> = ({
  currentView,
  onChangeView,
}) => {
  const { formatMessage, locale } = useIntl()
  const { goTo } = useRoute()
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const [userLanguage, setUserLanguage] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeState)

  useEffect(() => {
    if (currentLocale) {
      setUserLanguage(currentLocale)
    }
  }, [currentLocale])

  const handleMenuOpen = (): void => {
    setIsMenuOpen(true)
  }

  const handleMenuClose = (): void => {
    setIsMenuOpen(false)
  }

  const handleSelectLanguage = (language: Locale): void => {
    localStorage.setItem('userLanguage', String(language))
    setUserLanguage(language)
    setCurrentLocale(language)
  }

  const handleClickLogo = (): void => {
    goTo(Path.PUBLIC_CASE_LIST, true)
  }

  const handleToggleTheme = useCallback((): void => {
    const newThemeMode =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    localStorage.setItem('themeMode', newThemeMode)
    setThemeMode(newThemeMode)
  }, [themeMode])

  return (
    <TopBannerWrapper>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <LogoWrapper
          direction="row"
          spacing={2}
          alignItems="center"
          padding={1}
        >
          {publicPortalInfo?.logoUrl && (
            <img
              src={publicPortalInfo?.logoUrl}
              height="50px"
              onClick={handleClickLogo}
              alt={publicPortalInfo?.name}
            />
          )}
        </LogoWrapper>

        <Stack direction={'row'} spacing={2}>
          <IconButton
            sx={{ ml: 1 }}
            onClick={handleToggleTheme}
            color="inherit"
            aria-label={formatMessage({
              id:
                themeMode === ThemeMode.DARK
                  ? 'top_banner.light_theme'
                  : 'top_banner.dark_theme',
            })}
          >
            {themeMode === ThemeMode.DARK ? (
              <LightThemeIcon />
            ) : (
              <DarkThemeIcon />
            )}
          </IconButton>

          <IconButton
            color="inherit"
            aria-label={formatMessage({
              id: 'general.icon_button.main_menu_toggle',
            })}
            onClick={handleMenuOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Stack>
      </Stack>

      <TabsWrapper>
        <Tabs
          value={currentView}
          onChange={onChangeView}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            id="public-view-tab-case-add"
            aria-controls="public-view-tabpanel-case-add"
            label={formatMessage({
              id: 'consumer_report_add.header',
            })}
            icon={<PostAddIcon />}
            iconPosition="start"
            value={PublicView.CASE_ADD}
          />
          {publicPortalInfo && publicPortalInfo.type === PortalType.CITY && (
            <Tab
              id="public-view-tab-case-list"
              aria-controls="public-view-tabpanel-case-list"
              label={formatMessage({
                id: 'consumer_page.tab.case_list',
              })}
              icon={<SearchIcon />}
              iconPosition="start"
              value={PublicView.CASE_LIST}
            />
          )}
        </Tabs>
      </TabsWrapper>

      <MenuDrawer
        anchor="right"
        open={isMenuOpen}
        onClose={handleMenuClose}
        onOpen={handleMenuOpen}
      >
        <Stack spacing={1} height="100%">
          <Stack spacing={2} flexGrow={1}>
            <PortalTitle>{publicPortalInfo?.name}</PortalTitle>
            {publicPortalInfo &&
              publicPortalInfo.supportedLanguages.length > 1 && (
                <Select
                  value={userLanguage}
                  size="small"
                  inputProps={{
                    'aria-label': formatMessage({
                      id: 'general.select.language',
                    }),
                  }}
                >
                  {publicPortalInfo?.supportedLanguages.map((language) => (
                    <MenuItem
                      key={language}
                      value={language}
                      onClick={(): void => {
                        handleSelectLanguage(language)
                      }}
                    >
                      {SUPPORT_LOCALE_LABEL[language]}
                    </MenuItem>
                  ))}
                </Select>
              )}

            <Stack spacing={2} paddingTop={4}>
              <Link
                href={`https://www.preporti.com/${
                  PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                }faq`}
                target="_blank"
              >
                {formatMessage({ id: 'consumer_footer.link.faq' })}
              </Link>
              <Link
                href={`https://www.preporti.com/${
                  PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                }terms-of-use`}
                target="_blank"
              >
                {formatMessage({ id: 'consumer_footer.link.terms' })}
              </Link>
              <Link
                href={`https://www.preporti.com/${
                  PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                }privacy-policy`}
                target="_blank"
              >
                {formatMessage({ id: 'consumer_footer.link.privacy_settings' })}
              </Link>
              <Link
                href={`https://www.preporti.com/${
                  PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
                }`}
                target="_blank"
              >
                {formatMessage({ id: 'consumer_footer.link.about_us' })}
              </Link>
            </Stack>
          </Stack>

          <Stack spacing={2} alignItems="center" textAlign="center">
            <EportiLogo />

            <Typography variant="body2" sx={{ fontSize: '12px' }}>
              {formatMessage({ id: 'public_case.text.preporti_desc' })}
            </Typography>

            <Stack
              flexGrow={1}
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
              spacing={2}
            >
              <PublicDownloadAppLinks />
            </Stack>
          </Stack>
        </Stack>
      </MenuDrawer>
    </TopBannerWrapper>
  )
}

export default PublicTopBannerMobile
