import { useIntl } from 'react-intl'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import MuiDialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import MuiLink from '@mui/material/Link'

import { isPublicCaseCreatedState } from 'state/formStates'
import EportiQrCode from 'assets/icons/eporti_qr_code.svg'
import SucceedIcon from 'assets/icons/succeed.svg'
import EportiIcon from 'assets/icons/eporti_icon.svg'
import AppleStoreIcon from 'assets/icons/apple_store_black.svg'
import GoogleStoreIcon from 'assets/icons/google_store_black.svg'
import { isMobileViewState } from 'state/layoutStates'

const DownloadLink = styled(MuiLink)`
  display: flex;
`

const LogoWrapper = styled(Stack)`
  justify-content: center;
  background: #ff0058;
  color: white;
  padding: 12px 36px;
  font-size: 22px;
  font-weight: 500;
`

const DialogContent = styled(MuiDialogContent)`
  padding: 0px;
`

const PublicCaseCreatedDialog: React.FC = () => {
  const { formatMessage } = useIntl()
  const [isPublicCaseCreated, setIsPublicCaseCreated] = useRecoilState(
    isPublicCaseCreatedState,
  )
  const isMobileView = useRecoilValue(isMobileViewState)

  const handleClose = (): void => {
    setIsPublicCaseCreated(false)
  }

  return (
    <Dialog onClose={handleClose} open={isPublicCaseCreated} maxWidth="xs">
      <DialogTitle textAlign="center">
        <Stack alignItems="center" spacing={2}>
          <SucceedIcon />
          <Box textAlign="center">
            {formatMessage({ id: 'public_case.report_created' })}
          </Box>
        </Stack>
      </DialogTitle>
      <IconButton
        aria-label={formatMessage({ id: 'general.button.close' })}
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          <LogoWrapper direction="row" alignItems="center" spacing={2}>
            <Box>
              <EportiIcon />
            </Box>

            <Typography>
              {formatMessage({
                id: 'consumer_report_add.label.follow_eporti_app',
              })}
            </Typography>
          </LogoWrapper>
          <Stack
            paddingX={4}
            paddingY={2}
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="center"
          >
            <Stack spacing={2}>
              <Typography variant="body2">
                {formatMessage({
                  id: 'public_case.created_dialog.download_app',
                })}
              </Typography>
              <Stack direction="row" spacing={2}>
                <DownloadLink
                  aria-label={formatMessage({
                    id: 'general.icon_button.download_ios_app',
                  })}
                  href={process.env.REACT_APP_IOS_APP_LINK}
                  target="_blank"
                >
                  <AppleStoreIcon />
                </DownloadLink>
                <DownloadLink
                  aria-label={formatMessage({
                    id: 'general.icon_button.download_android_app',
                  })}
                  href={process.env.REACT_APP_ANDROID_APP_LINK}
                  target="_blank"
                >
                  <GoogleStoreIcon />
                </DownloadLink>
              </Stack>
            </Stack>
            {!isMobileView && (
              <Box>
                <EportiQrCode />
              </Box>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default PublicCaseCreatedDialog
