import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Routes, Route } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { SWRConfig } from 'swr'
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics'
import { SnackbarProvider } from 'notistack'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { darkThemeOptions, lightThemeOptions } from 'theme'

import AuthContext from './context/AuthContext'
import AppBootstrap from 'AppBootstrap'
import { currentLocaleState } from 'state/portalSettingStates'
import {
  isMobileViewState,
  screenSizeState,
  themeModeState,
} from 'state/layoutStates'
import { networkErrorState } from 'state/errorStates'
import {
  DEFAULT_LOCALE,
  Path,
  SUPPORT_LOCALES,
  ThemeMode,
} from './commonConstants'
import Loader from 'components/loader/Loader'
import AuthGuard from './components/guard/AuthGuard'
import MainLayout from './layout/MainLayout'
import LoginLayout from './layout/LoginLayout'
import CaseListPage from './pages/CaseListPage'
import CaseDetailPage from './pages/CaseDetailPage'
import VideoCallPage from './pages/VideoCallPage'
import LoginPage from './pages/LoginPage'
import CaseAddPage from './pages/CaseAddPage'
import PortalEditPage from 'pages/PortalEditPage'
import PortalListPage from 'pages/PortalListPage'
import ItemAddPage from 'pages/ItemAddPage'
import ItemListPage from 'pages/ItemListPage'
import CustomerAddPage from 'pages/CustomerAddPage'
import CustomerEditPage from 'pages/CustomerEditPage'
import CustomerListPage from 'pages/CustomerListPage'
import MemberListPage from 'pages/MemberListPage'
import MemberEditPage from 'pages/MemberEditPage'
import MemberAddPage from 'pages/MemberAddPage'
import WithMenuLayout from 'layout/WithMenuLayout'
import PublicCaseAddPage from 'pages/PublicCaseAddPage'
import SignUpPage from 'pages/SignUpPage'
import ForgotPasswordPage from 'pages/ForgotPasswordPage'
import CaseEditPage from 'pages/CaseEditPage'
import RoleListPage from 'pages/RoleListPage'
import RoleEditPage from 'pages/RoleEditPage'
import MyProfile from 'pages/MyProfile'
import AuthActionPage from 'pages/AuthActionPage'
import PublicCaseAddPageMobile from 'pages/PublicCaseAddPageMobile'
import TeamListPage from 'pages/TeamListPage'
import TeamEditPage from 'pages/TeamEditPage'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import LogoutPage from 'pages/LogoutPage'
import AlertListPage from 'pages/AlertListPage'
import ContactListPage from 'pages/ContactListPage'
import ContactAddPage from 'pages/ContactAddPage'
import ContactEditPage from 'pages/ContactEditPage'
import CommentTemplateListPage from 'pages/CommentTemplateListPage'
import CommentTemplateEditPage from 'pages/CommentTemplateEditPage'
import PollListPage from 'pages/PollListPage'
import PollEditPage from 'pages/PollEditPage'
import NewsListPage from 'pages/NewsListPage'
import NewsEditPage from 'pages/NewsEditPage'
import CategoryListPage from 'pages/CategoryListPage'
import CategoryEditPage from 'pages/CategoryEditPage'
import { columnVisibilityState } from 'state/caseListStates'
import { CaseListColumn } from 'components/case/caseConstants'
import CategoryCodeEditorPage from 'pages/CategoryCodeEditorPage'
import TaskTemplateListPage from 'pages/TaskTemplateListPage'
import TaskTemplateEditPage from 'pages/TaskTemplateEditPage'
import WorkflowEditPage from 'pages/WorkflowEditPage'
import WorkflowListPage from 'pages/WorkflowListPage'
import AppPublicBootstrap from 'AppPublicBootstrap'
import RedirectWithQuery from 'components/helpers/RedirectWithQuery'
import OfferListPage from 'pages/OfferListPage'
import OfferEditPage from 'pages/OfferEditPage'
import StatisticsPage from 'pages/StatisticsPage'
import NotFoundPage from 'pages/NotFoundPage'
import ItemEditPage from 'pages/ItemEditPage'
import ItemAdvancedEditPage from 'pages/ItemAdvancedEditPage'

const AppProviders: React.FC = () => {
  const { authData } = useContext(AuthContext)
  const [messages, setMessages] = useState<Record<string, string>>({})
  const currentLocale = useRecoilValue(currentLocaleState)
  const setNetworkError = useSetRecoilState(networkErrorState)
  const [isMobileView, setIsMobileView] = useRecoilState(isMobileViewState)
  const themeMode = useRecoilValue(themeModeState)
  const setColumnVisibility = useSetRecoilState(columnVisibilityState)
  const setScreenSize = useSetRecoilState(screenSizeState)
  const analytics = getAnalytics()
  setAnalyticsCollectionEnabled(
    analytics,
    process.env.REACT_APP_ENV === 'production',
  )

  const isMobileUserAgent = (): boolean => {
    return /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(
      navigator.userAgent,
    )
  }

  useLayoutEffect(() => {
    if (isMobileUserAgent()) {
      setIsMobileView(true)
      setColumnVisibility({
        [CaseListColumn.IMAGE]: true,
        [CaseListColumn.ADDRESS_AND_TITLE]: true,
        [CaseListColumn.SHAREABILITY]: false,
        [CaseListColumn.STATUS]: true,
        [CaseListColumn.CREATED_BY]: false,
        [CaseListColumn.UPDATED]: false,
        [CaseListColumn.CREATED]: false,
      })
    }

    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    )

    setScreenSize({
      width: vw,
      height: vh,
    })
  }, [])

  const loadMessages = async (
    locale: string,
  ): Promise<Record<string, string>> => {
    const selectedLocal = SUPPORT_LOCALES.includes(locale.toUpperCase())
      ? locale
      : DEFAULT_LOCALE

    document.documentElement.lang = selectedLocal.toLowerCase()
    const messagesData = await import(
      `./locales/${selectedLocal.toLowerCase()}.json`
    )
    if (locale === DEFAULT_LOCALE) {
      const newMessagesData = await import(`./locales/en-new.json`)

      return {
        ...messagesData,
        ...newMessagesData,
      }
    }

    return messagesData
  }

  const fetchMessages = async (newLocale: string): Promise<void> => {
    const messagesData = await loadMessages(newLocale)

    setMessages(messagesData)
  }

  useEffect(() => {
    if (currentLocale) {
      void fetchMessages(currentLocale)
    }
  }, [currentLocale])

  const fetcher = useCallback(
    async (url: string): Promise<unknown> => {
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')

      if (authData) {
        const token = await authData.getIdToken()
        headers.append('Authorization', `Bearer ${token}`)
      }

      const res = await fetch(url, {
        headers,
      })

      if (!res.ok) {
        const error = await res.text()
        setNetworkError(error)
        return await Promise.reject(new Error(error))
      }

      return await res.json()
    },
    [authData],
  )

  const theme = useMemo(
    () =>
      createTheme(
        themeMode === ThemeMode.LIGHT ? lightThemeOptions : darkThemeOptions,
      ),

    [themeMode],
  )

  if (Object.keys(messages).length === 0) {
    return <Loader />
  }

  return (
    <IntlProvider locale={currentLocale} messages={messages}>
      <SWRConfig
        value={{
          fetcher,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <Routes>
              <Route
                path="/publicCases"
                element={<RedirectWithQuery path="/public" />}
              />
              <Route
                path={Path.LOGIN}
                element={
                  <LoginLayout>
                    <LoginPage />
                  </LoginLayout>
                }
              />
              <Route path={Path.LOGOUT} element={<LogoutPage />} />
              <Route
                path={Path.AUTH}
                element={
                  <LoginLayout>
                    <AuthActionPage />
                  </LoginLayout>
                }
              />
              <Route
                path={Path.FORGOT_PASSWORD}
                element={
                  <LoginLayout>
                    <ForgotPasswordPage />
                  </LoginLayout>
                }
              />
              <Route
                path={Path.SIGN_UP}
                element={
                  <LoginLayout>
                    <SignUpPage />
                  </LoginLayout>
                }
              />
              <Route path="/public" element={<AppPublicBootstrap />}>
                <Route
                  path={Path.PUBLIC_CASE_ADD}
                  element={
                    isMobileView ? (
                      <PublicCaseAddPageMobile />
                    ) : (
                      <PublicCaseAddPage />
                    )
                  }
                />
              </Route>

              <Route path="/" element={<AppBootstrap />}>
                <Route
                  path={Path.UNAUTHORIZED}
                  element={
                    <MainLayout>
                      <UnauthorizedPage />
                    </MainLayout>
                  }
                />

                <Route
                  path={Path.PROFILE}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <MyProfile />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.CASES_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CaseListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.CASES_LIST}/:caseId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <CaseDetailPage />
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.CASES_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <CaseAddPage />
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.CASES_EDIT}/:caseId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <CaseEditPage />
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.MEMBERS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <MemberListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.MEMBERS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <MemberAddPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.MEMBERS_EDIT}/:memberId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <MemberEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.TEAMS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <TeamListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.TEAMS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <TeamEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.TEAMS_EDIT}/:teamId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <TeamEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.CUSTOMERS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CustomerListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.CUSTOMERS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CustomerAddPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.CUSTOMERS_EDIT}/:customerId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CustomerEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.ALERTS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <AlertListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.POLLS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <PollListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.POLLS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <PollEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.POLLS_EDIT}/:pollId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <PollEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.ITEMS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ItemListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.ITEMS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ItemAddPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.ITEMS_EDIT}/:itemId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ItemEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.ITEMS_ADVANCED_EDIT}/:itemId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ItemAdvancedEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.ROLES_LIST}/:selectedIndex`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <RoleListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.ROLES_EDIT}/:selectedIndex`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <RoleEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.PORTALS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <PortalListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={Path.PORTALS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <PortalEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
                <Route
                  path={`${Path.PORTALS_EDIT}/:portalId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <PortalEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.CATEGORIES_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CategoryListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.CATEGORIES_EDIT}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CategoryEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.CATEGORIES_EDITOR}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CategoryCodeEditorPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.VIDEO_CALL_CASE}/:caseId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <VideoCallPage />
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.CONTACTS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ContactListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.CONTACTS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ContactAddPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.CONTACTS_EDIT}/:contactId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <ContactEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.COMMENT_TEMPLATES_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CommentTemplateListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.COMMENT_TEMPLATES_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CommentTemplateEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.COMMENT_TEMPLATES_EDIT}/:templateId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <CommentTemplateEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.NEWS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <NewsListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.NEWS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <NewsEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.NEWS_EDIT}/:newsId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <NewsEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.TASK_TEMPLATES_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <TaskTemplateListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.TASK_TEMPLATES_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <TaskTemplateEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.TASK_TEMPLATES_EDIT}/:templateId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <TaskTemplateEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.WORKFLOWS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <WorkflowListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.WORKFLOWS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <WorkflowEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.WORKFLOWS_EDIT}/:workflowId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <WorkflowEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.OFFERS_LIST}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <OfferListPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.OFFERS_ADD}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <OfferEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={`${Path.OFFERS_EDIT}/:offerId`}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <OfferEditPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />

                <Route
                  path={Path.STATISTICS}
                  element={
                    <AuthGuard
                      component={
                        <MainLayout>
                          <WithMenuLayout>
                            <StatisticsPage />
                          </WithMenuLayout>
                        </MainLayout>
                      }
                    />
                  }
                />
              </Route>

              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </SnackbarProvider>
        </ThemeProvider>
      </SWRConfig>
    </IntlProvider>
  )
}

export default AppProviders
