import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  useForm,
  type SubmitHandler,
  Controller,
  useFieldArray,
} from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import useSWR from 'swr'
import {
  Chip,
  Stack,
  FormControl,
  TextField,
  FormHelperText,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  IconButton,
  Checkbox,
  OutlinedInput,
  ListItemText,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
  SubSubHeader,
  SubContentWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { hasUnsavedChangesState } from 'state/formStates'
import useApi from 'hooks/useApi'
import {
  type WorkflowInputs,
  type Workflow,
  type WorkflowFormFields,
  type WorkflowFormData,
  type WorkflowConditionFormFields,
  type WorkflowActionFormFields,
  type TaskTemplateForAction,
} from 'components/workflow/workflowTypes'
import {
  WORKFLOW_CASE_SOURCE_LABEL,
  WORKFLOW_CONDITION_LABEL,
  WORKFLOW_DESCRIPTION_MAX_LENGTH,
  WORKFLOW_FORM_ACTION_LABEL,
  WORKFLOW_NAME_MAX_LENGTH,
  WORKFLOW_TRIGGER_LABEL,
  WorkflowCaseSource,
  WorkflowCondition,
  WorkflowFormAction,
  WorkflowTrigger,
} from 'components/workflow/workflowConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import CategorySelect from 'components/category/select/CategorySelect'
import { sortCategories } from 'utils/categoryUtils'
import { type CategoryBasic } from 'components/category/categoryTypes'

const WorkflowEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { workflowId } = useParams()
  const [searchParams] = useSearchParams()
  const isCopy = searchParams.get('isCopy')
  const { goTo } = useRoute()
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [workflowInputs, setWorkflowInputs] = useState<WorkflowInputs | null>(
    null,
  )
  const [isLoadingInputs, setIsLoadingInputs] = useState(true)
  const { getLocalizedContent } = usePortalSetting()
  const { data: workflowData, isLoading } = useSWR<Workflow>(
    portalSetting && workflowId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/workflows/${workflowId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    getValues,
    watch,
    clearErrors,
  } = useForm<WorkflowFormFields>({
    mode: 'onTouched',
    defaultValues: {
      conditions: [],
      actions: [],
    },
  })
  const {
    fields: conditionsFields,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({
    control,
    name: 'conditions',
  })
  const {
    fields: actionsFields,
    append: appendAction,
    remove: removeAction,
  } = useFieldArray({
    control,
    name: 'actions',
  })
  const conditions = watch('conditions')
  const actions = watch('actions')

  const getWorkflowInputsFields = useCallback(async (): Promise<void> => {
    if (portalSetting) {
      const response = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/workflows:listInputs`,
      )
      const fieldsData = await response.json()
      setIsLoadingInputs(false)

      if (fieldsData) {
        setWorkflowInputs(fieldsData)
      }
    }
  }, [portalSetting])

  useEffect(() => {
    if (portalSetting) {
      void getWorkflowInputsFields()

      if (!workflowId) {
        appendAction({
          action: null,
        })
      }
    }
  }, [portalSetting, workflowId])

  useEffect(() => {
    if (workflowData) {
      setValue('name', workflowData.name)
      setValue('description', workflowData.description)
      setValue('trigger', workflowData.trigger)
      const conditions: WorkflowConditionFormFields[] = []

      if (workflowData.conditions.items.length > 0) {
        conditions.push({
          condition: WorkflowCondition.ITEMS,
          itemIds: workflowData.conditions.items.map((item) => item.id),
        })
      }

      if (workflowData.conditions.categories.length > 0) {
        conditions.push({
          condition: WorkflowCondition.CATEGORIES,
          categoryIds: workflowData.conditions.categories.map(
            (category) => category.id,
          ),
        })
      }

      if (workflowData.conditions.caseSources.length > 0) {
        conditions.push({
          condition: WorkflowCondition.CASE_SOURCES,
          caseSources: workflowData.conditions.caseSources,
        })
      }

      setValue('conditions', conditions)

      const actions: WorkflowActionFormFields[] = []

      if (workflowData.actions.length > 0) {
        workflowData.actions.forEach((action) => {
          if (action.taskToCreate) {
            actions.push({
              action: WorkflowFormAction.ADD_TASK,
              taskTemplateId: action.taskToCreate.id,
              teamId: action.teamToAssign?.id,
              teamMemberId: action.teamMemberToAssign?.id,
            })
          }

          if (action.taskFromCaseDescription) {
            actions.push({
              action: WorkflowFormAction.ADD_TASK,
              taskTemplateId: '-1',
              teamId: action.teamToAssign?.id,
              teamMemberId: action.teamMemberToAssign?.id,
            })
          }
        })
      }

      setValue('actions', actions)
    }
  }, [workflowData])

  useEffect(() => {
    setHasUnsavedChanges(isDirty)
  }, [isDirty])

  const handleGoBack = (): void => {
    goTo(Path.WORKFLOWS_LIST)
  }

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      Object.keys(errors).length > 0 ||
      isSaving ||
      actions.length === 0,
    [isValid, errors, isSaving, actions],
  )

  const onSubmit: SubmitHandler<WorkflowFormFields> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const formData: WorkflowFormData = {
            name: data.name,
            description: data.description,
            trigger: data.trigger,
            actions: data.actions,
          }

          const itemIds = data.conditions.reduce<string[]>(
            (acc, cur) => [...acc, ...(cur.itemIds ?? [])],
            [],
          )
          const categoryIds = data.conditions.reduce<string[]>(
            (acc, cur) => [...acc, ...(cur.categoryIds ?? [])],
            [],
          )
          const caseSources = data.conditions.reduce<WorkflowCaseSource[]>(
            (acc, cur) => [...acc, ...(cur.caseSources ?? [])],
            [],
          )

          formData.condition = {
            itemIds,
            categoryIds,
            caseSources,
          }

          if (data.actions.length > 0) {
            formData.actions = data.actions.map((action) => ({
              taskTemplateId:
                action.taskTemplateId && action.taskTemplateId !== '-1'
                  ? action.taskTemplateId
                  : undefined,
              taskFromCaseDescription: action.taskTemplateId === '-1',
              teamId: action.teamId ? action.teamId : undefined,
              teamMemberId:
                action.teamMemberId !== '-1' ? action.teamMemberId : undefined,
              commentTemplateId: action.commentTemplateId
                ? action.commentTemplateId
                : undefined,
            }))
          }

          if (workflowId && !isCopy) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/workflows/${workflowId}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/workflows`,
              formData,
            )
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(Path.WORKFLOWS_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting],
  )

  const handleAddCondition = (): void => {
    appendCondition({
      condition: null,
    })
  }

  const handleAddAction = (): void => {
    appendAction({
      action: null,
    })
  }

  const shouldDisableCategoriesCondition = useMemo(
    () =>
      conditions.some(
        (condition) => condition.condition === WorkflowCondition.CATEGORIES,
      ),
    [conditions],
  )

  const shouldDisableItemsCondition = useMemo(
    () =>
      conditions.some(
        (condition) => condition.condition === WorkflowCondition.ITEMS,
      ),
    [conditions],
  )

  const shouldDisableCaseSourcesCondition = useMemo(
    () =>
      conditions.some(
        (condition) => condition.condition === WorkflowCondition.CASE_SOURCES,
      ),
    [conditions],
  )

  const selectedItemsIds = conditions.reduce<string[]>(
    (acc, cur) => [...acc, ...(cur.itemIds ?? [])],
    [],
  )

  const availableTaskTemplates = useMemo(() => {
    const templates: TaskTemplateForAction[] = [
      {
        id: '-1',
        name: formatMessage({
          id: 'task_edit_form.templates.use_case_description',
        }),
        itemIds: [...selectedItemsIds],
      },
    ]

    if (
      !workflowInputs?.actions?.taskTemplates ||
      workflowInputs?.actions?.taskTemplates.length === 0
    ) {
      return templates
    }

    if (selectedItemsIds.length === 0) {
      return [...templates, ...workflowInputs?.actions?.taskTemplates]
    }

    const filteredTemplates = workflowInputs?.actions?.taskTemplates.filter(
      (taskTemplate) =>
        taskTemplate.itemIds.some((itemId) =>
          selectedItemsIds.includes(itemId),
        ),
    )

    return [...templates, ...filteredTemplates]
  }, [workflowInputs?.actions?.taskTemplates, selectedItemsIds])

  if (isLoading || !portalSetting || isLoadingInputs) {
    return <CircularProgress />
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {workflowId &&
                  !isCopy &&
                  formatMessage({ id: 'workflow_edit.header' })}
                {workflowId &&
                  isCopy &&
                  formatMessage({ id: 'workflow_copy.header' })}
                {!workflowId && formatMessage({ id: 'workflow_add.header' })}
              </MainHeader>
            </Stack>

            {(!workflowId || workflowData?._operations.canEdit) && (
              <LoadingButton
                variant="contained"
                size="small"
                type="submit"
                disabled={shouldDisableSaving}
                loading={isSaving}
              >
                {formatMessage({ id: 'general.button.save' })}
              </LoadingButton>
            )}
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <FormControl error={!!errors.name}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: WORKFLOW_NAME_MAX_LENGTH,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.name}
                      required
                      size="small"
                      label={formatMessage({
                        id: 'workflow_edit.label.name',
                      })}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {errors.name?.type === 'maxLength' && (
                  <FormHelperText>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: WORKFLOW_NAME_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {errors.name?.type === 'required' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.required',
                    })}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl error={!!errors.description}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: WORKFLOW_DESCRIPTION_MAX_LENGTH,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <MultiLineInput
                      {...field}
                      required
                      maxLength={WORKFLOW_DESCRIPTION_MAX_LENGTH}
                      error={!!errors?.description}
                      label={formatMessage({
                        id: 'workflow_edit.label.description',
                      })}
                      variant="outlined"
                      fullWidth
                      rows={3}
                    />
                  )}
                />
                {errors.description?.type === 'maxLength' && (
                  <FormHelperText>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: WORKFLOW_DESCRIPTION_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {errors.name?.type === 'required' && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'general.error.required',
                    })}
                  </FormHelperText>
                )}
              </FormControl>

              <Stack direction="row" spacing={2}>
                <Stack flex={1} spacing={2}>
                  <SubSubHeader>
                    {formatMessage({
                      id: 'workflow_edit.label.when',
                    })}{' '}
                    *
                  </SubSubHeader>

                  <FormControl error={!!errors.trigger}>
                    <InputLabel id="workflow-event-label">
                      {formatMessage({
                        id: 'workflow_edit.label.select_event',
                      })}
                    </InputLabel>
                    <Controller
                      name="trigger"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={WorkflowTrigger.CASE_CREATED}
                      render={({ field }) => (
                        <Select
                          {...field}
                          required
                          labelId="workflow-event-label"
                          label={formatMessage({
                            id: 'workflow_edit.label.select_event',
                          })}
                          size="small"
                          fullWidth
                          variant="outlined"
                        >
                          {workflowInputs?.triggers.map((trigger) => (
                            <MenuItem
                              key={trigger}
                              value={trigger}
                              disabled={
                                !workflowInputs?.triggers.includes(trigger)
                              }
                            >
                              {formatMessage({
                                id: WORKFLOW_TRIGGER_LABEL[trigger],
                              })}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Stack>
                <Stack flex={1} spacing={2}>
                  <SubSubHeader>
                    {formatMessage({
                      id: 'workflow_edit.label.and',
                    })}
                  </SubSubHeader>

                  {conditionsFields.map((condition, index) => (
                    <SubContentWrapper key={index} spacing={1}>
                      <FormControl>
                        <Controller
                          name={`conditions.${index}.condition`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          defaultValue={null}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              required
                              onChange={(event) => {
                                field.onChange(event)
                                clearErrors(`conditions.${index}`)
                              }}
                              size="small"
                              fullWidth
                              label={formatMessage({
                                id: 'workflow_edit.label.select_condition',
                              })}
                              variant="outlined"
                            >
                              {Object.keys(WorkflowCondition).map(
                                (workflowCondition) => (
                                  <MenuItem
                                    key={workflowCondition}
                                    value={workflowCondition}
                                    disabled={
                                      (shouldDisableCategoriesCondition &&
                                        workflowCondition ===
                                          WorkflowCondition.CATEGORIES) ||
                                      (shouldDisableItemsCondition &&
                                        workflowCondition ===
                                          WorkflowCondition.ITEMS) ||
                                      (shouldDisableCaseSourcesCondition &&
                                        workflowCondition ===
                                          WorkflowCondition.CASE_SOURCES)
                                    }
                                  >
                                    {formatMessage({
                                      id: WORKFLOW_CONDITION_LABEL[
                                        workflowCondition as WorkflowCondition
                                      ],
                                    })}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          )}
                        />
                      </FormControl>

                      {conditions[index].condition ===
                        WorkflowCondition.ITEMS && (
                        <FormControl>
                          <InputLabel id="item-select-label" shrink required>
                            {formatMessage({
                              id: 'workflow_edit.label.select_item',
                            })}
                          </InputLabel>
                          <Controller
                            name={`conditions.${index}.itemIds`}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            defaultValue={[]}
                            render={({ field }) => (
                              <Select
                                {...field}
                                required
                                multiple
                                labelId="item-select-label"
                                input={
                                  <OutlinedInput
                                    notched
                                    label={formatMessage({
                                      id: 'workflow_edit.label.select_item',
                                    })}
                                  />
                                }
                                size="small"
                                variant="outlined"
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => {
                                      const selectedItem =
                                        workflowInputs?.portalItems?.find(
                                          (item) => item.id === value,
                                        )

                                      return (
                                        <Chip
                                          key={value}
                                          size="small"
                                          label={getLocalizedContent(
                                            selectedItem?.names,
                                          )}
                                        />
                                      )
                                    })}
                                  </Box>
                                )}
                              >
                                {workflowInputs?.portalItems?.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    <Checkbox
                                      checked={getValues(
                                        `conditions.${index}.itemIds`,
                                      )?.includes(item.id)}
                                    />
                                    <ListItemText
                                      primary={getLocalizedContent(item.names)}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </FormControl>
                      )}

                      {conditions[index].condition ===
                        WorkflowCondition.CATEGORIES && (
                        <FormControl>
                          <Controller
                            name={`conditions.${index}.categoryIds`}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            defaultValue={[]}
                            render={({ field }) => (
                              <CategorySelect
                                required
                                variant="outlined"
                                label={formatMessage({
                                  id: 'workflow_edit.label.select_category',
                                })}
                                value={field.value ?? []}
                                onChange={field.onChange}
                                categories={sortCategories<CategoryBasic>(
                                  workflowInputs?.categories ?? [],
                                )}
                                renderValue={
                                  !!field.value && field.value.length > 0
                                    ? formatMessage(
                                        {
                                          id: 'case_filter.option.n_selected',
                                        },
                                        {
                                          count: field.value.length,
                                        },
                                      )
                                    : ''
                                }
                              />
                            )}
                          />
                        </FormControl>
                      )}

                      {conditions[index].condition ===
                        WorkflowCondition.CASE_SOURCES && (
                        <FormControl>
                          <InputLabel
                            id="case-source-select-label"
                            shrink
                            required
                          >
                            {formatMessage({
                              id: 'workflow_edit.label.select_source',
                            })}
                          </InputLabel>
                          <Controller
                            name={`conditions.${index}.caseSources`}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            defaultValue={[]}
                            render={({ field }) => (
                              <Select
                                {...field}
                                required
                                multiple
                                labelId="case-source-select-label"
                                input={
                                  <OutlinedInput
                                    notched
                                    label={formatMessage({
                                      id: 'workflow_edit.label.select_source',
                                    })}
                                  />
                                }
                                size="small"
                                variant="outlined"
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => {
                                      return (
                                        <Chip
                                          key={value}
                                          size="small"
                                          label={formatMessage({
                                            id: WORKFLOW_CASE_SOURCE_LABEL[
                                              value
                                            ],
                                          })}
                                        />
                                      )
                                    })}
                                  </Box>
                                )}
                              >
                                {Object.keys(WorkflowCaseSource)?.map(
                                  (source) => (
                                    <MenuItem key={source} value={source}>
                                      <Checkbox
                                        checked={getValues(
                                          `conditions.${index}.caseSources`,
                                        )?.includes(
                                          source as WorkflowCaseSource,
                                        )}
                                      />
                                      <ListItemText
                                        primary={formatMessage({
                                          id: WORKFLOW_CASE_SOURCE_LABEL[
                                            source as WorkflowCaseSource
                                          ],
                                        })}
                                      />
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      )}

                      <Box>
                        <IconButton
                          aria-label={formatMessage({
                            id: 'general.icon_button.delete',
                          })}
                          size="small"
                          onClick={() => {
                            removeCondition(index)
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </SubContentWrapper>
                  ))}

                  <Box textAlign="right">
                    <Button
                      size="small"
                      startIcon={<AddIcon fontSize="small" />}
                      onClick={handleAddCondition}
                      variant="outlined"
                    >
                      {formatMessage({
                        id: 'workflow_edit.button.add_condition',
                      })}
                    </Button>
                  </Box>
                </Stack>
                <Stack flex={1} spacing={2}>
                  <SubSubHeader>
                    {formatMessage({
                      id: 'workflow_edit.label.then',
                    })}{' '}
                    *
                  </SubSubHeader>

                  {actionsFields.map((action, index) => (
                    <SubContentWrapper key={index} spacing={1}>
                      <FormControl>
                        <Controller
                          name={`actions.${index}.action`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          defaultValue={null}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              required
                              size="small"
                              fullWidth
                              label={formatMessage({
                                id: 'workflow_edit.label.select_action',
                              })}
                              variant="outlined"
                            >
                              {Object.keys(WorkflowFormAction).map(
                                (workflowAction) => (
                                  <MenuItem
                                    key={workflowAction}
                                    value={workflowAction}
                                  >
                                    {formatMessage({
                                      id: WORKFLOW_FORM_ACTION_LABEL[
                                        workflowAction as WorkflowFormAction
                                      ],
                                    })}
                                  </MenuItem>
                                ),
                              )}
                            </TextField>
                          )}
                        />
                      </FormControl>

                      {actions[index].action ===
                        WorkflowFormAction.ADD_TASK && (
                        <>
                          <FormControl>
                            <Controller
                              name={`actions.${index}.taskTemplateId`}
                              control={control}
                              rules={{
                                required: true,
                              }}
                              defaultValue={''}
                              render={({ field }) => (
                                <TextField
                                  select
                                  {...field}
                                  required
                                  size="small"
                                  fullWidth
                                  label={formatMessage({
                                    id: 'workflow_edit.label.select_task_template',
                                  })}
                                  variant="outlined"
                                >
                                  {availableTaskTemplates.map(
                                    (taskTemplate) => (
                                      <MenuItem
                                        key={taskTemplate.id}
                                        value={taskTemplate.id}
                                      >
                                        {taskTemplate.name}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextField>
                              )}
                            />
                          </FormControl>

                          <FormControl>
                            <Controller
                              name={`actions.${index}.teamId`}
                              control={control}
                              defaultValue={''}
                              render={({ field }) => (
                                <TextField
                                  select
                                  {...field}
                                  size="small"
                                  fullWidth
                                  label={formatMessage({
                                    id: 'workflow_edit.label.assign_task',
                                  })}
                                  variant="outlined"
                                >
                                  {workflowInputs?.actions?.teamsToAssign.map(
                                    (team) => (
                                      <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                      </MenuItem>
                                    ),
                                  )}
                                </TextField>
                              )}
                            />
                          </FormControl>

                          <FormControl>
                            <Controller
                              name={`actions.${index}.teamMemberId`}
                              control={control}
                              defaultValue={'-1'}
                              render={({ field }) => (
                                <TextField
                                  select
                                  {...field}
                                  size="small"
                                  fullWidth
                                  label={formatMessage({
                                    id: 'workflow_edit.label.select_member',
                                  })}
                                  variant="outlined"
                                >
                                  <MenuItem value="-1">
                                    {formatMessage({
                                      id: 'task_edit_form.option.no_assignee',
                                    })}
                                  </MenuItem>
                                  {workflowInputs?.actions?.teamMembersToAssign
                                    .filter((member) =>
                                      member.teamIds.some(
                                        (teamId) =>
                                          teamId === actions[index].teamId,
                                      ),
                                    )
                                    .map((member) => (
                                      <MenuItem
                                        key={member.id}
                                        value={member.id}
                                      >
                                        {member.name}
                                      </MenuItem>
                                    ))}
                                </TextField>
                              )}
                            />
                          </FormControl>
                        </>
                      )}

                      <Box>
                        <IconButton
                          aria-label={formatMessage({
                            id: 'general.icon_button.delete',
                          })}
                          size="small"
                          onClick={() => {
                            removeAction(index)
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </SubContentWrapper>
                  ))}

                  <Box textAlign="right">
                    <Button
                      size="small"
                      startIcon={<AddIcon fontSize="small" />}
                      onClick={handleAddAction}
                      variant="outlined"
                    >
                      {formatMessage({
                        id: 'workflow_edit.button.add_action',
                      })}
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default WorkflowEditPage
