import { Link } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'

const NotFoundPage: React.FC = () => {
  return (
    <Box textAlign="center" mt={5}>
      <Typography variant="h2" color="error">
        404
      </Typography>
      <Typography variant="h5">Oops! Page Not Found</Typography>
      <Typography variant="body1" color="textSecondary" mt={2}>
        The page you are looking for doesn&#39;t exist or has been moved.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3 }}
        component={Link}
        to="/"
      >
        Go to Home
      </Button>
    </Box>
  )
}

export default NotFoundPage
