import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import dayjs from 'dayjs'
import useSWR, { mutate } from 'swr'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add'

import useRoute from 'hooks/useNavigate'
import { type CaseInfo } from 'components/case/caseTypes'
import {
  casesDataState,
  currentCaseListViewState,
  offsetState,
  selectedCaseShareabilitiesState,
  selectedCasePeriodState,
  selectedCaseStatusesState,
  sortModelState,
  keywordState,
  selectedCaseCategoriesState,
  selectedPortalItemsState,
} from 'state/caseListStates'
import CaseFilter from 'components/case/CaseFilter'
import { MAP_VIEW_MAX_MARKERS, Path } from '../commonConstants'
import {
  Period,
  CurrentCaseListView,
  CASE_SHAREABILITIES_DEFAULT,
  CASE_STATUSES_DEFAULT,
  CASE_PERIODS_DEFAULT,
  CaseOrder,
} from 'components/case/caseConstants'
import { portalSettingState } from 'state/portalSettingStates'
import CaseList from 'components/case/CaseList'
import CaseListMap from 'components/case/CaseListMap'
import { MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyCaseIcon from 'assets/icons/empty_case.svg'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import usePortalSetting from 'hooks/usePortalSetting'

const CaseListPage: React.FC = () => {
  const { goTo } = useRoute()
  const { formatMessage } = useIntl()
  const [selectedCaseShareabilities, setSelectedCaseShareabilities] =
    useRecoilState(selectedCaseShareabilitiesState)
  const [selectedCaseStatuses, setSelectedCaseStatuses] = useRecoilState(
    selectedCaseStatusesState,
  )
  const [selectedCasePeriod, setSelectedCasePeriod] = useRecoilState(
    selectedCasePeriodState,
  )
  const [selectedCaseCategories, setSelectedCaseCategories] = useRecoilState(
    selectedCaseCategoriesState,
  )
  const [selectedPortalItems, setSelectedPortalItems] = useRecoilState(
    selectedPortalItemsState,
  )
  const [casesData, setCasesData] = useRecoilState(casesDataState)
  const offset = useRecoilValue(offsetState)

  const currentCaseListView = useRecoilValue(currentCaseListViewState)

  const portalSetting = useRecoilValue(portalSettingState)
  const sortModel = useRecoilValue(sortModelState)
  const [keyword, setKeyword] = useRecoilState(keywordState)
  const [caseListUrl, setCaseListUrl] = useState<string | null>(null)
  const { checkAccesses } = useMember()
  const { resetFilters } = usePortalSetting()
  const analytics = getAnalytics()

  useEffect(() => {
    logEvent(analytics, 'web_case_list_page_view')
  }, [])

  useEffect(() => {
    if (portalSetting) {
      const baseUrl = `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting.id
      }/cases`
      const baseParams = []

      if (currentCaseListView === CurrentCaseListView.MAP) {
        baseParams.push(`recordsNum=${MAP_VIEW_MAX_MARKERS}`)
        baseParams.push(`orderAttributeState=UPDATED`)
        baseParams.push(`order=DESCENDING`)
      } else {
        baseParams.push(`offset=${offset}`)
        if (sortModel.length) {
          baseParams.push(`orderAttribute=${sortModel[0].field.toUpperCase()}`)
          baseParams.push(
            `order=${
              sortModel[0].sort === 'desc'
                ? CaseOrder.DESCENDING
                : CaseOrder.ASCENDING
            }`,
          )
        }
      }

      if (keyword) {
        baseParams.push(`keyword=${keyword}`)
      }

      const categories = selectedCaseCategories.map(
        (category) => `categoryIds=${category}`,
      )

      const items = selectedPortalItems.map((itemId) => `itemIds=${itemId}`)

      const shareabilities = selectedCaseShareabilities.map(
        (shareability) => `shareability=${shareability}`,
      )

      const statuses = selectedCaseStatuses.map((status) => `status=${status}`)

      const periods: string[] = []
      const currentDate = dayjs()
      if (selectedCasePeriod === Period.WEEK) {
        const oneWeekAgo = currentDate.subtract(1, 'week')
        periods.push(`createdAfter=${oneWeekAgo.toISOString()}`)
        periods.push(`createdBefore=${currentDate.toISOString()}`)
      } else if (selectedCasePeriod === Period.MONTH) {
        const oneMonthAgo = currentDate.subtract(1, 'month')
        periods.push(`createdAfter=${oneMonthAgo.toISOString()}`)
        periods.push(`createdBefore=${currentDate.toISOString()}`)
      } else if (selectedCasePeriod === Period.YEAR) {
        const oneYearAgo = currentDate.subtract(1, 'year')
        periods.push(`createdAfter=${oneYearAgo.toISOString()}`)
        periods.push(`createdBefore=${currentDate.toISOString()}`)
      }

      setCaseListUrl(
        `${baseUrl}?${[
          ...baseParams,
          ...categories,
          ...items,
          ...shareabilities,
          ...statuses,
          ...periods,
        ].join('&')}`,
      )
    }
  }, [
    portalSetting?.id,
    offset,
    selectedCaseCategories,
    selectedPortalItems,
    selectedCaseShareabilities,
    selectedCaseStatuses,
    selectedCasePeriod,
    currentCaseListView,
    sortModel,
    keyword,
  ])

  const {
    data: casesResponseData,
    isLoading,
    isValidating,
  } = useSWR<CaseInfo[]>(caseListUrl, {
    revalidateOnFocus: false,
    refreshInterval: 60000,
  })

  useEffect(() => {
    if (casesResponseData && casesResponseData.length > 0) {
      setCasesData((prev) =>
        [...prev, ...casesResponseData].reduce((acc: CaseInfo[], caseInfo) => {
          const existingCaseIndex = acc.findIndex(
            (item) => item.id === caseInfo.id,
          )

          if (existingCaseIndex > -1) {
            acc[existingCaseIndex] = caseInfo
          } else {
            acc.push(caseInfo)
          }
          return acc
        }, []),
      )
    }

    if (casesResponseData && offset === 0) {
      setCasesData([...casesResponseData])
    }
  }, [casesResponseData, offset])

  const handleAddCase = (): void => {
    goTo(Path.CASES_ADD)
  }

  const handleRefresh = (): void => {
    if (caseListUrl) {
      void mutate(caseListUrl)
    }
  }

  const handleCaseClick = useCallback(
    (data: CaseInfo): void => {
      if (portalSetting) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/cases/${data.id}`,
          data,
          false,
        )
      }

      goTo(`${Path.CASES_LIST}/${data.id}`)
    },
    [portalSetting],
  )

  const isDefaultFiltersValue = useMemo((): boolean => {
    return (
      selectedCaseCategories.length === 0 &&
      selectedCasePeriod === CASE_PERIODS_DEFAULT &&
      selectedCaseStatuses.toString() === CASE_STATUSES_DEFAULT.toString() &&
      selectedCaseShareabilities.toString() ===
        CASE_SHAREABILITIES_DEFAULT.toString() &&
      keyword === ''
    )
  }, [
    selectedCaseCategories,
    selectedCaseStatuses,
    selectedCasePeriod,
    selectedCaseShareabilities,
    keyword,
  ])

  const handleResetFilters = (): void => {
    setSelectedCaseCategories([])
    setSelectedPortalItems([])
    setSelectedCaseShareabilities(CASE_SHAREABILITIES_DEFAULT)
    setSelectedCaseStatuses(CASE_STATUSES_DEFAULT)
    setSelectedCasePeriod(CASE_PERIODS_DEFAULT)
    setKeyword('')
    resetFilters()
  }

  return (
    <Stack height={'100%'}>
      <Grid container>
        <Grid flexGrow={1} alignSelf={'center'}>
          <MainHeader>{formatMessage({ id: 'case_list.header' })}</MainHeader>
        </Grid>
        <Grid textAlign={'right'}>
          <Stack direction={'row'} spacing={2}>
            <Box alignSelf={'center'}>
              {checkAccesses({
                [PortalSection.CASES]: [FeatureAccess.READ],
              }) && (
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={handleAddCase}
                  data-testid="add-case-button"
                  color="secondary"
                >
                  {formatMessage({ id: 'case_list.button.create_report' })}
                </Button>
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <CaseFilter />

      <Box flexGrow={1} overflow="hidden">
        {currentCaseListView === CurrentCaseListView.LIST && (
          <CaseList
            isLoading={isLoading}
            onCaseClick={handleCaseClick}
            isDefaultFiltersValue={isDefaultFiltersValue}
            onResetFilters={handleResetFilters}
          />
        )}

        {currentCaseListView === CurrentCaseListView.MAP && (
          <CaseListMap
            caseListWidth={400}
            isLoading={isLoading || isValidating}
            onRefresh={handleRefresh}
            isDefaultFiltersValue={isDefaultFiltersValue}
            onResetFilters={handleResetFilters}
            onCaseClick={handleCaseClick}
          />
        )}
      </Box>

      {!casesData?.length &&
        !isLoading &&
        isDefaultFiltersValue &&
        checkAccesses({
          [PortalSection.CASES]: [FeatureAccess.READ],
        }) && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyCaseIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'case_list.label.no_cases',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={handleAddCase}
                  data-testid="add-empty-case-button"
                  color="secondary"
                >
                  {formatMessage({ id: 'case_list.button.create_report' })}
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}
    </Stack>
  )
}

export default CaseListPage
