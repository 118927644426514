import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MuiLink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { PREPORTI_SUPPORT_LOCALES_MAP } from '../../commonConstants'
import EportiLogo from 'assets/icons/eporti_white_logo.svg'
import PublicDownloadAppLinks from 'components/publicCase/PublicDownloadAppLinks'

const Wrapper = styled(Stack)`
  width: 100%;
  background: #000621;
  color: #fff;
  padding: 8px 12px;
  align-items: center;
`

const Link = styled(MuiLink)`
  color: inherit;
  font-weight: 500;
`

const PublicFooter: React.FC = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Wrapper direction="row">
      <Stack flex={1}>
        <EportiLogo />
      </Stack>

      <Stack direction="row" spacing={4}>
        <Link
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }faq`}
          target="_blank"
        >
          {formatMessage({ id: 'consumer_footer.link.faq' })}
        </Link>
        <Link
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }terms-of-use`}
          target="_blank"
        >
          {formatMessage({ id: 'consumer_footer.link.terms' })}
        </Link>
        <Link
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }privacy-policy`}
          target="_blank"
        >
          {formatMessage({ id: 'consumer_footer.link.privacy_settings' })}
        </Link>
        <Link
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }`}
          target="_blank"
        >
          {formatMessage({ id: 'consumer_footer.link.about_us' })}
        </Link>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
        flex={1}
      >
        <Typography variant="body1">
          {formatMessage({ id: 'consumer_footer.text.send_porti' })}
        </Typography>
        <PublicDownloadAppLinks />
      </Stack>
    </Wrapper>
  )
}

export default PublicFooter
