import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  Stack,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'
import SearchIcon from '@mui/icons-material/Search'

import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import {
  Path,
  PortalType,
  SUPPORT_LOCALE_LABEL,
  ThemeMode,
} from '../../commonConstants'
import { type Locale } from 'types'
import useRoute from 'hooks/useNavigate'
import { themeModeState } from 'state/layoutStates'

export enum PublicView {
  CASE_ADD = 'CASE_ADD',
  CASE_LIST = 'CASE_LIST',
}

type PublicTopBannerProps = {
  currentView: PublicView
  onChangeView: (event: React.SyntheticEvent, newView: PublicView) => void
}

const TopBannerWrapper = styled(Stack)`
  box-shadow: 0px 4px 8px 0px rgba(35, 45, 66, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  height: 96px;
  padding: 0 16px;
`

const TabsWrapper = styled(Stack)`
  height: 100%;
  flex-direction: column-reverse;
`

const Tabs = styled(MuiTabs)`
  min-height: 44px;
`

const Tab = styled(MuiTab)`
  padding: 10px 16px;
  min-height: 0;
  color: inherit;
  font-weight: 500;
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.error.main};
    background: ${({ theme }) =>
      theme.palette.mode === 'dark' ? theme.palette.common.black : '#f3f3f7'};
  }
`

const LeftContainer = styled(Stack)`
  & img {
    cursor: pointer;
  }
`

const PublicTopBanner: React.FC<PublicTopBannerProps> = ({
  currentView,
  onChangeView,
}) => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const [userLanguage, setUserLanguage] = useState('')
  const [currentLocale, setCurrentLocale] = useRecoilState(currentLocaleState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeState)

  useEffect(() => {
    if (currentLocale) {
      setUserLanguage(currentLocale)
    }
  }, [currentLocale])

  const handleSelectLanguage = (language: Locale): void => {
    localStorage.setItem('publicUserLanguage', String(language))
    setUserLanguage(language)
    setCurrentLocale(language)
  }

  const handleClickLogo = (): void => {
    goTo(Path.PUBLIC_CASE_LIST)
  }

  const handleToggleTheme = useCallback((): void => {
    const newThemeMode =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    localStorage.setItem('themeMode', newThemeMode)
    setThemeMode(newThemeMode)
  }, [themeMode])

  return (
    <TopBannerWrapper direction="row">
      <LeftContainer direction="row" spacing={4} alignItems="center" flex={1}>
        {publicPortalInfo?.logoUrl && (
          <img
            src={publicPortalInfo?.logoUrl}
            height="70px"
            onClick={handleClickLogo}
            alt={publicPortalInfo?.name}
          />
        )}

        <TabsWrapper>
          <Tabs
            value={currentView}
            onChange={onChangeView}
            TabIndicatorProps={{ style: { display: 'none' } }}
          >
            <Tab
              id="public-view-tab-case-add"
              aria-controls="public-view-tabpanel-case-add"
              label={formatMessage({
                id: 'consumer_report_add.header',
              })}
              icon={<PostAddIcon />}
              iconPosition="start"
              value={PublicView.CASE_ADD}
            />
            {publicPortalInfo && publicPortalInfo.type === PortalType.CITY && (
              <Tab
                id="public-view-tab-case-list"
                aria-controls="public-view-tabpanel-case-list"
                label={formatMessage({
                  id: 'consumer_page.tab.case_list',
                })}
                icon={<SearchIcon />}
                iconPosition="start"
                value={PublicView.CASE_LIST}
              />
            )}
          </Tabs>
        </TabsWrapper>
      </LeftContainer>

      <Stack direction={'row'} spacing={2} paddingY={1} alignItems="center">
        {publicPortalInfo && publicPortalInfo.supportedLanguages.length > 1 && (
          <Select
            value={userLanguage}
            size="small"
            inputProps={{
              'aria-label': formatMessage({
                id: 'general.select.language',
              }),
            }}
          >
            {publicPortalInfo?.supportedLanguages.map((language) => (
              <MenuItem
                key={language}
                value={language}
                onClick={(): void => {
                  handleSelectLanguage(language)
                }}
              >
                {SUPPORT_LOCALE_LABEL[language]}
              </MenuItem>
            ))}
          </Select>
        )}

        <IconButton
          sx={{ ml: 1 }}
          onClick={handleToggleTheme}
          color="inherit"
          aria-label={formatMessage({
            id:
              themeMode === ThemeMode.DARK
                ? 'top_banner.light_theme'
                : 'top_banner.dark_theme',
          })}
        >
          {themeMode === ThemeMode.DARK ? (
            <LightThemeIcon />
          ) : (
            <DarkThemeIcon />
          )}
        </IconButton>
      </Stack>
    </TopBannerWrapper>
  )
}

export default PublicTopBanner
