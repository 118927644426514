import { useEffect, useState } from 'react'

const useURLState = <T extends string | string[] | null>(
  key: string,
  defaultValue: T,
): [T, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(() => {
    const params = new URLSearchParams(window.location.search)
    if (Array.isArray(defaultValue)) {
      return (params.getAll(key) as T) || defaultValue
    }
    return (params.get(key) as T) || defaultValue
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (Array.isArray(value)) {
      params.delete(key)
      value.forEach((v) => {
        params.append(key, v)
      })
    } else {
      if (value) {
        params.set(key, value)
      } else {
        params.delete(key)
      }
    }
    window.history.replaceState({}, '', `?${params.toString()}`)
  }, [key, value])

  return [value, setValue]
}

export default useURLState
