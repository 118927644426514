import { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useIntl } from 'react-intl'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { nanoid } from 'nanoid'
import {
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import ContactEditForm from 'components/item/ContactEditForm'
import { type ContactFormField } from 'components/item/itemTypes'
import { portalSettingState } from 'state/portalSettingStates'
import ErrorIcon from 'assets/icons/error_icon.svg'
import ContactCard from 'components/item/ContactCard'

type ContactListFormProps = {
  contacts: ContactFormField[]
  onSaveContact: (data: ContactFormField, contactId: string) => void
  onDeleteContact: (contactId: string) => void
  onMoveContact: (dragIndex: number, hoverIndex: number) => void
}

const ContactListForm: React.FC<ContactListFormProps> = ({
  contacts,
  onSaveContact,
  onDeleteContact,
  onMoveContact,
}) => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const [selectedContactsIds, setSelectedContactsIds] = useState<Set<string>>(
    new Set(),
  )
  const [
    isDeleteContactConfirmDialogOpen,
    setIsDeleteContactConfirmDialogOpen,
  ] = useState(false)
  const deletingContactId = useRef<null | string>(null)

  const handleAddContact = (): void => {
    const newIds = new Set(selectedContactsIds)
    newIds.add('-1')
    setSelectedContactsIds(newIds)
  }

  const handleCloseContactForm = (contactId: string): void => {
    const newIds = new Set(selectedContactsIds)
    newIds.delete(contactId)
    setSelectedContactsIds(newIds)
  }

  const handleOpenDeleteContactConfirmDialog = (contactId: string): void => {
    deletingContactId.current = contactId
    setIsDeleteContactConfirmDialogOpen(true)
  }

  const handleDeleteContact = (): void => {
    if (
      deletingContactId.current !== null &&
      deletingContactId.current !== '-1'
    ) {
      onDeleteContact(deletingContactId.current)
      setIsDeleteContactConfirmDialogOpen(false)
    }
  }

  const handleCloseDeleteContactConfirmDialog = (): void => {
    setIsDeleteContactConfirmDialogOpen(false)
    deletingContactId.current = null
  }

  const handleEditContact = (contactId: string): void => {
    const newIds = new Set(selectedContactsIds)
    newIds.add(contactId)
    setSelectedContactsIds(newIds)
  }

  if (!portalSetting) {
    return null
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack spacing={2}>
        {contacts.map((contact, index) => (
          <ContactCard
            key={contact.id}
            index={index}
            contact={contact}
            selectedContactsIds={selectedContactsIds}
            defaultLanguage={portalSetting.defaultLanguage}
            onSaveContact={onSaveContact}
            onEditContact={handleEditContact}
            onOpenDeleteContactConfirmDialog={
              handleOpenDeleteContactConfirmDialog
            }
            onCloseContactForm={handleCloseContactForm}
            onMoveContact={onMoveContact}
          />
        ))}

        {selectedContactsIds.has('-1') && (
          <ContactEditForm
            onSave={(data: ContactFormField) => {
              onSaveContact({ ...data, id: nanoid(6) }, '-1')
            }}
            onClose={() => {
              handleCloseContactForm('-1')
            }}
          />
        )}

        <Box textAlign="right">
          <Button
            size="small"
            startIcon={<AddIcon fontSize="small" />}
            onClick={handleAddContact}
            variant="outlined"
          >
            {formatMessage({
              id: 'portal_item_edit.button.add_contact',
            })}
          </Button>
        </Box>

        <Dialog open={isDeleteContactConfirmDialogOpen}>
          <DialogTitle>
            <Stack alignItems="center" spacing={2}>
              <ErrorIcon />
              <Box textAlign="center">
                {formatMessage({
                  id: 'portal_item_edit.delete_contact_confirm.title',
                })}
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent sx={{ textAlign: 'center' }}>
            {formatMessage({
              id: 'portal_item_edit.delete_contact_confirm.content',
            })}
          </DialogContent>
          <DialogActions>
            <Stack spacing={1} width="100%">
              <Button
                onClick={handleDeleteContact}
                variant="contained"
                fullWidth
                color="error"
              >
                {formatMessage({
                  id: 'general.icon_button.delete',
                })}
              </Button>
              <Button
                onClick={handleCloseDeleteContactConfirmDialog}
                variant="outlined"
                fullWidth
                color="error"
                autoFocus
              >
                {formatMessage({
                  id: 'general.button.cancel',
                })}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
    </DndProvider>
  )
}

export default ContactListForm
