import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  quotePlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  BlockTypeSelect,
  ListsToggle,
  CreateLink,
  type MDXEditorMethods,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import {
  Box,
  Button,
  Stack,
  FormControl,
  InputAdornment,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Typography,
  FormLabel,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import CottageIcon from '@mui/icons-material/Cottage'
import LanguageIcon from '@mui/icons-material/Language'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
  MDEditor,
  FormFieldsWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type Locale } from 'types'
import { Path, ThemeMode } from '../commonConstants'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import {
  hasNewFileState,
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import TranslationDialog from 'components/helpers/TranslationDialog'
import useApi from 'hooks/useApi'
import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  convertLocalizedStringToData,
  convertDataToLocalizedString,
} from 'utils/stringUtils'
import ImageUploader from 'components/form/ImageUploader'
import { themeModeState } from 'state/layoutStates'
import {
  OFFER_TITLE_MAX_LENGTH,
  OfferStatus,
} from 'components/offer/offerConstants'
import {
  type OfferPayload,
  type Offer,
  type OfferFormFields,
} from 'components/offer/offerTypes'
import DateInput from 'components/form/DateInput'
import OfferPreviewDialog from 'components/offer/OfferPreviewDialog'

const FORM_NAME = 'EDIT_OFFER'
const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 500
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 500

const OfferEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { offerId } = useParams()
  const [searchParams] = useSearchParams()
  const isCopy = searchParams.get('isCopy')
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const translationContext = useRef<'titles' | 'descriptions' | null>(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState<
    number | undefined
  >(OFFER_TITLE_MAX_LENGTH)
  const [isRichEditor, setIsRichEditor] = useState(false)
  const [items, setItems] = useState<ItemBasic[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const { getLocalizedContent } = usePortalSetting()
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const hasNewFile = useRecoilValue(hasNewFileState)
  const [isActivateConfirmDialogOpen, setIsActivateConfirmDialogOpen] =
    useState(false)
  const editorRef = useRef<MDXEditorMethods>(null)
  const themeMode = useRecoilValue(themeModeState)
  const [isExpiryDisabled, setIsExpiryDisabled] = useState(true)
  const { data: offerData, isLoading } = useSWR<Offer>(
    portalSetting && offerId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/offers/${offerId}`
      : null,
  )
  const {
    control,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
  } = useForm<OfferFormFields>({
    mode: 'onTouched',
  })

  const titles = watch('titles')
  const descriptions = watch('descriptions')

  useEffect(() => {
    if (offerData) {
      setValue('titles', convertLocalizedStringToData(offerData.titles))
      setValue(
        'descriptions',
        convertLocalizedStringToData(offerData.descriptions),
      )
      setValue('itemId', offerData.item.id)
      if (offerData.url) {
        setValue('url', offerData.url)
      }
      setValue('multipleUse', !offerData.singleUse)
    }
  }, [offerData])

  useEffect(() => {
    if (descriptions && editorRef.current && portalSetting) {
      editorRef.current.setMarkdown(descriptions[portalSetting.defaultLanguage])
    }
  }, [descriptions, editorRef.current, portalSetting])

  useEffect(() => {
    setValue('url', processedFormFiles?.[0]?.url)
  }, [processedFormFiles])

  const getItems = async (): Promise<void> => {
    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/offers:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)
    setIsLoadingItems(false)
  }

  useEffect(() => {
    if (portalSetting) {
      void getItems()
    }
  }, [portalSetting])

  const hasUnsavedChanges = useMemo(
    () => hasNewFile || isDirty,
    [hasNewFile, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const handleGoBack = (): void => {
    goTo(Path.OFFERS_LIST)
  }

  const handleOpenTitleTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    setIsRichEditor(false)
    translationContext.current = 'titles'
    setMaxTranslationLength(OFFER_TITLE_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenDescriptionTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    setIsRichEditor(true)
    setMaxTranslationLength(undefined)
    translationContext.current = 'descriptions'
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [translationContext.current])

  const validateTranslations = (
    name: 'titles' | 'descriptions',
  ): null | string => {
    if (!portalSetting) {
      return null
    }

    const data = getValues(name)

    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const missingTitlesTranslation = useMemo(
    () => !!dirtyFields.titles && !!validateTranslations('titles'),
    [titles],
  )

  const shouldDisableSaving = useMemo(
    () =>
      (!isValid ||
        Object.keys(errors).length > 0 ||
        isLoadingItems ||
        isSaving ||
        isFileUploading ||
        missingTitlesTranslation) &&
      !hasNewFile,
    [
      isValid,
      errors,
      isLoadingItems,
      isSaving,
      missingTitlesTranslation,
      isFileUploading,
      hasNewFile,
    ],
  )

  const onSave = useCallback(
    async (data: OfferFormFields, shouldActivate: boolean): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          const formData: OfferPayload = {
            itemId: data.itemId,
            titles: convertDataToLocalizedString(data.titles),
            descriptions: convertDataToLocalizedString(data.descriptions),
            activate: shouldActivate,
            singleUse: !data.multipleUse,
            url: data.url,
          }

          if (data.expiry) {
            formData.expiry = data.expiry.format()
          }

          if (offerId && !isCopy) {
            await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/offers/${offerId}`,
              formData,
            )
          } else {
            await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/offers`,
              formData,
            )
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          goTo(Path.OFFERS_LIST, true)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting, offerId],
  )

  const handlePublishNews = (): void => {
    const data = getValues()
    void onSave(data, true)
  }

  const handleSaveAsDraft = (): void => {
    const data = getValues()
    void onSave(data, false)
  }

  const handleSave = useCallback((): void => {
    const data = getValues()
    void onSave(data, offerData?.status === OfferStatus.ACTIVATED)
  }, [offerData])

  const handleOpenPreviewDialog = (): void => {
    setIsPreviewDialogOpen(true)
  }

  const handleClosePreviewDialog = (): void => {
    setIsPreviewDialogOpen(false)
  }

  const handleCloseActivateConfirmDialog = (): void => {
    setIsActivateConfirmDialogOpen(false)
  }

  const handleOpenActivateConfirmDialog = (): void => {
    setIsActivateConfirmDialogOpen(true)
  }

  const handleToggleExpirationDate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue('expiry', null)
    setIsExpiryDisabled(!event.target.checked)
  }

  if ((offerId && isLoading) || !portalSetting) {
    return <CircularProgress />
  }

  return (
    <>
      <Stack height={'100%'} width={'100%'}>
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <BackButtonGrey
              onClick={handleGoBack}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.go_back',
              })}
            >
              <ArrowBackIcon />
            </BackButtonGrey>

            <MainHeader>
              {offerId && !isCopy && formatMessage({ id: 'offer_edit.header' })}
              {offerId && isCopy && formatMessage({ id: 'offer_copy.header' })}
              {!offerId && formatMessage({ id: 'offer_add.header' })}
            </MainHeader>
          </Stack>

          <Button size="small" onClick={handleOpenPreviewDialog}>
            {formatMessage({ id: 'offer_edit.button.preview' })}
          </Button>

          {!isCopy && offerData?._operations.canEdit && (
            <LoadingButton
              variant="contained"
              size="small"
              disabled={shouldDisableSaving}
              loading={isSaving}
              onClick={handleSave}
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          )}

          {(!offerId || isCopy) && (
            <LoadingButton
              variant="outlined"
              size="small"
              disabled={shouldDisableSaving}
              loading={isSaving}
              onClick={handleSaveAsDraft}
            >
              {formatMessage({ id: 'offer_edit.button.save_as_draft' })}
            </LoadingButton>
          )}

          {(!offerId || offerData?._operations.canActivate || isCopy) && (
            <LoadingButton
              variant="contained"
              size="small"
              color="secondary"
              disabled={shouldDisableSaving}
              loading={isSaving}
              onClick={handleOpenActivateConfirmDialog}
            >
              {formatMessage({ id: 'offer_edit.button.activate' })}
            </LoadingButton>
          )}
        </Stack>

        <SettingWrapper width="100%" flexGrow={1}>
          <Stack paddingY={2} paddingX={4} spacing={2}>
            {(!offerId || offerData?._operations.canEditItems || isCopy) && (
              <FormControl fullWidth error={!!errors.itemId}>
                <InputLabel id="items-label" size="small" required>
                  {formatMessage({
                    id: 'offer_edit.label.item',
                  })}
                </InputLabel>
                <Controller
                  name="itemId"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={null}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      labelId="items-label"
                      label={formatMessage({
                        id: 'offer_edit.label.item',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <CottageIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      input={
                        <OutlinedInput
                          label={formatMessage({
                            id: 'offer_edit.label.item',
                          })}
                        />
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                    >
                      {items?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {getLocalizedContent(item.names)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            )}

            <Stack width="100%">
              <Box paddingY={2} height={300}>
                <ImageUploader
                  formName={FORM_NAME}
                  maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                  maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                  defaultImageUrl={offerData?.url}
                  hintText={formatMessage({
                    id: 'image_uploader.hint.recommended_ratio_4_3',
                  })}
                />
              </Box>

              {supportLanguages.length > 1 && (
                <Stack direction="row" justifyContent="end">
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleOpenTitleTranslationDialog}
                  >
                    {formatMessage({
                      id: 'portal_item_edit.button.manage_localization',
                    })}
                  </Button>
                </Stack>
              )}

              <FormControl>
                <Controller
                  name={`titles.${portalSetting.defaultLanguage}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    maxLength: OFFER_TITLE_MAX_LENGTH,
                    required: true,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      error={!!errors.titles}
                      size="small"
                      label={`${formatMessage({
                        id: 'comment_template_edit.label.name',
                      })}  ${
                        supportLanguages.length < 2
                          ? ''
                          : ` (${portalSetting.defaultLanguage})`
                      }`}
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <LanguageIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          ),
                        },
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />

                {!!dirtyFields.titles && !!validateTranslations('titles') && (
                  <FormHelperText error>
                    {formatMessage(
                      {
                        id: 'portal_item_edit.error.missing_translations',
                      },
                      {
                        missingTranslations: validateTranslations('titles'),
                      },
                    )}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <Stack width="100%">
              {supportLanguages.length > 1 && (
                <Stack direction="row" alignItems="center">
                  <FormLabel required sx={{ flexGrow: 1, fontSize: 14 }}>
                    {formatMessage({
                      id: 'offer_edit.label.content',
                    })}
                  </FormLabel>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleOpenDescriptionTranslationDialog}
                  >
                    {formatMessage({
                      id: 'portal_item_edit.button.manage_localization',
                    })}
                  </Button>
                </Stack>
              )}

              <FormControl>
                <Controller
                  name={`descriptions.${portalSetting.defaultLanguage}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <MDEditor
                      ref={editorRef}
                      className={
                        themeMode === ThemeMode.DARK ? 'dark-theme' : ''
                      }
                      markdown=""
                      suppressHtmlProcessing={true}
                      onChange={(markdown) => {
                        field.onChange(markdown.replaceAll('&#x20;', ' '))
                      }}
                      plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        linkPlugin(),
                        linkDialogPlugin(),
                        quotePlugin(),
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              {' '}
                              <UndoRedo />
                              <BlockTypeSelect />
                              <BoldItalicUnderlineToggles
                                options={['Bold', 'Italic']}
                              />
                              <ListsToggle options={['bullet', 'number']} />
                              <CreateLink />
                            </>
                          ),
                        }),
                      ]}
                    />
                  )}
                />

                {!!dirtyFields.descriptions &&
                  !!validateTranslations('descriptions') && (
                    <FormHelperText error>
                      {formatMessage(
                        {
                          id: 'portal_item_edit.error.missing_translations',
                        },
                        {
                          missingTranslations:
                            validateTranslations('descriptions'),
                        },
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Stack>

            <FormFieldsWrapper>
              <FormControlLabel
                value="start"
                disableTypography
                control={
                  <Switch
                    checked={!isExpiryDisabled}
                    onChange={handleToggleExpirationDate}
                  />
                }
                label={
                  <Stack width="100%">
                    <Typography>
                      {formatMessage({
                        id: 'offer_edit.label.expiration_date_switch',
                      })}
                    </Typography>
                  </Stack>
                }
                labelPlacement="start"
              />

              <FormControl error={!!errors?.expiry}>
                <Controller
                  name="expiry"
                  control={control}
                  rules={{
                    validate: (val): boolean | string => {
                      if (!val) {
                        return true
                      }

                      if (val.toDate() < new Date()) {
                        return 'validate'
                      }

                      return true
                    },
                  }}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      disabled={isExpiryDisabled}
                      label={formatMessage({
                        id: 'offer_edit.label.expiration_date',
                      })}
                      disablePast={true}
                      showTime={true}
                    />
                  )}
                />
              </FormControl>
            </FormFieldsWrapper>

            <FormFieldsWrapper>
              <FormControlLabel
                value="start"
                disableTypography
                control={
                  <Controller
                    name="multipleUse"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value}
                        color="primary"
                        onChange={(e) => {
                          field.onChange(e)
                          setValue('multipleUse', e.target.checked)
                        }}
                      />
                    )}
                  />
                }
                label={
                  <Stack width="100%">
                    <Typography>
                      {formatMessage({
                        id: 'offer_edit.label.multiple_use',
                      })}
                    </Typography>
                  </Stack>
                }
                labelPlacement="start"
              />
            </FormFieldsWrapper>
          </Stack>
        </SettingWrapper>
      </Stack>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
        isRichEditor={isRichEditor}
      />

      <OfferPreviewDialog
        isOpen={isPreviewDialogOpen}
        imageUrl={processedFormFiles?.[0]?.url || offerData?.url}
        onClose={handleClosePreviewDialog}
        titles={titles}
        descriptions={descriptions}
        singleUse={offerData?.singleUse}
        expiry={offerData?.expiry}
      />

      <Dialog open={isActivateConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'offer_edit.confirm.activate.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'offer_edit.confirm.activate.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={handlePublishNews}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'offer_edit.button.activate',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseActivateConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OfferEditPage
