import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Tooltip from '@mui/material/Tooltip'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { MainHeader, StrongText, SubHeader } from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { Path } from 'commonConstants'
import ErrorIcon from 'assets/icons/error_icon.svg'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import useRoute from 'hooks/useNavigate'
import EmptyWorkflowIcon from 'assets/icons/empty_workflow.svg'
import { type Workflow } from 'components/workflow/workflowTypes'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const ViewTabsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  & .MuiButton-endIcon .counter {
    padding: 0px 8px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-text {
    & .counter {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

const WorkflowWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};

    .sub-content {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`

const WorkflowListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendDeleteRequest, sendPostRequest } = useApi()
  const [isSaving, setIsSaving] = useState(false)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null,
  )
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] =
    useState(false)
  const [isDeactivateConfirmDialogOpen, setIsDeactivateConfirmDialogOpen] =
    useState(false)
  const [isActivateConfirmDialogOpen, setIsActivateConfirmDialogOpen] =
    useState(false)
  const [currentView, setCurrentView] = useState<'active' | 'inactive'>(
    'active',
  )
  const {
    data: workflowsData,
    isLoading,
    mutate: mutateWorkflows,
  } = useSWR<Workflow[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/workflows`
      : null,
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    workflow: Workflow,
  ): void => {
    event.stopPropagation()
    setMenuEl(event.currentTarget)
    setSelectedWorkflow(workflow)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleOpenDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(true)
    handleCloseMenu()
  }

  const handleCloseDeleteConfirmDialog = (): void => {
    setIsDeleteConfirmDialogOpen(false)
  }

  const handleOpenDeactivateConfirmDialog = (): void => {
    setIsDeactivateConfirmDialogOpen(true)
    handleCloseMenu()
  }

  const handleCloseDeactivateConfirmDialog = (): void => {
    setIsDeactivateConfirmDialogOpen(false)
  }

  const handleOpenActivateConfirmDialog = (): void => {
    setIsActivateConfirmDialogOpen(true)
    handleCloseMenu()
  }

  const handleCloseActivateConfirmDialog = (): void => {
    setIsActivateConfirmDialogOpen(false)
  }

  const activeWorkflows = useMemo(
    () => workflowsData?.filter((workflow) => workflow.active) ?? [],
    [workflowsData],
  )

  const inactiveWorkflows = useMemo(
    () => workflowsData?.filter((workflow) => !workflow.active) ?? [],
    [workflowsData],
  )

  const currentWorkflows = useMemo(
    () => (currentView === 'active' ? activeWorkflows : inactiveWorkflows),
    [currentView, activeWorkflows, inactiveWorkflows],
  )

  const handleEditWorkflow = useCallback((): void => {
    if (selectedWorkflow && portalSetting) {
      void mutate(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/workflowsEdit/${selectedWorkflow.id}`,
        selectedWorkflow,
        false,
      )

      goTo(`${Path.WORKFLOWS_EDIT}/${selectedWorkflow.id}`)
    }
  }, [portalSetting, selectedWorkflow])

  const deleteWorkflow = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedWorkflow) {
      return
    }

    try {
      setIsSaving(true)
      await sendDeleteRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/workflows/${selectedWorkflow.id}`,
      )

      handleCloseDeleteConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateWorkflows()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedWorkflow])

  const deactivateWorkflow = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedWorkflow) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/workflows/${selectedWorkflow.id}:deactivate`,
      )

      handleCloseDeactivateConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateWorkflows()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedWorkflow])

  const activateWorkflow = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedWorkflow) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/workflows/${selectedWorkflow.id}:activate`,
      )

      handleCloseActivateConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateWorkflows()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedWorkflow])

  const handleCopy = useCallback((): void => {
    if (selectedWorkflow && portalSetting) {
      void mutate(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/workflows/${selectedWorkflow.id}`,
        selectedWorkflow,
        false,
      )

      goTo(`${Path.WORKFLOWS_EDIT}/${selectedWorkflow?.id}?isCopy=true`)
    }
  }, [portalSetting, selectedWorkflow])

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'workflow_list.header' })}
            <Tooltip
              title={formatMessage({
                id: 'workflow_list.label.creating_workflow_help_text',
              })}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {workflowsData &&
          workflowsData?.length > 0 &&
          checkAccesses({
            [PortalSection.WORKFLOWS]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.WORKFLOWS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'workflow_list.button.create_workflow',
              })}
            </Button>
          )}
      </Stack>

      <ViewTabsWrapper direction="row" padding={1} spacing={1}>
        <Button
          size="small"
          variant={currentView === 'active' ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView('active')
          }}
          endIcon={
            activeWorkflows.length > 0 && (
              <Box className="counter">{activeWorkflows.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'workflow_list.view.active' })}
        </Button>
        <Button
          size="small"
          variant={currentView === 'inactive' ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView('inactive')
          }}
          endIcon={
            inactiveWorkflows.length > 0 && (
              <Box className="counter">{inactiveWorkflows.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'workflow_list.view.inactive' })}
        </Button>
      </ViewTabsWrapper>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {workflowsData &&
          workflowsData.length === 0 &&
          checkAccesses({
            [PortalSection.WORKFLOWS]: [FeatureAccess.WRITE],
          }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyWorkflowIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'workflow_list.label.start_creating_workflow',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'workflow_list.label.creating_workflow_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      goTo(Path.WORKFLOWS_ADD)
                    }}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'workflow_list.button.create_workflow',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {currentWorkflows?.map((workflow) => (
          <WorkflowWrapper
            key={workflow.id}
            direction="row"
            spacing={2}
            padding={2}
            onClick={(event) => {
              if (
                checkAccesses({
                  [PortalSection.WORKFLOWS]: [FeatureAccess.WRITE],
                })
              ) {
                goTo(`${Path.WORKFLOWS_EDIT}/${workflow.id}`)
              }
            }}
          >
            <Stack flexGrow={1}>
              <StrongText>{workflow.name}</StrongText>
              <Typography variant="body2">{workflow.description}</Typography>
            </Stack>

            {checkAccesses({
              [PortalSection.WORKFLOWS]: [FeatureAccess.WRITE],
            }) && (
              <Stack>
                <IconButton
                  onClick={(event) => {
                    handleOpenMenu(event, workflow)
                  }}
                  size="small"
                  aria-label={formatMessage({
                    id: 'general.icon_button.see_more',
                  })}
                  role="button"
                >
                  <MoreVertIcon
                    sx={{
                      fontSize: 16,
                    }}
                  />
                </IconButton>
              </Stack>
            )}
          </WorkflowWrapper>
        ))}
      </Stack>

      <Menu
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        role="menu"
      >
        {selectedWorkflow?._operations.canEdit && (
          <MenuItem onClick={handleEditWorkflow}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.edit',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedWorkflow?._operations.canCopy && (
          <MenuItem onClick={handleCopy}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.copy',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedWorkflow?._operations.canDelete && (
          <MenuItem onClick={handleOpenDeleteConfirmDialog}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedWorkflow?._operations.canDeactivate && (
          <MenuItem onClick={handleOpenDeactivateConfirmDialog}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.deactivate',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedWorkflow?._operations.canActivate && (
          <MenuItem onClick={handleOpenActivateConfirmDialog}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.activate',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={isDeleteConfirmDialogOpen} maxWidth="sm">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({
                id: 'workflow_list.confirm.delete.title',
              })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'workflow_list.confirm.delete.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void deleteWorkflow()
              }}
              autoFocus
              variant="contained"
              color="secondary"
            >
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseDeleteConfirmDialog}
              variant="outlined"
              color="secondary"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isDeactivateConfirmDialogOpen} maxWidth="sm">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({
                id: 'workflow_list.confirm.deactivate.title',
              })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'workflow_list.confirm.deactivate.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void deactivateWorkflow()
              }}
              autoFocus
              variant="contained"
              color="secondary"
            >
              {formatMessage({
                id: 'general.icon_button.deactivate',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseDeactivateConfirmDialog}
              variant="outlined"
              color="secondary"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isActivateConfirmDialogOpen} maxWidth="sm">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({
                id: 'workflow_list.confirm.activate.title',
              })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'workflow_list.confirm.activate.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void activateWorkflow()
              }}
              autoFocus
              variant="contained"
              color="secondary"
            >
              {formatMessage({
                id: 'general.icon_button.activate',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseActivateConfirmDialog}
              variant="outlined"
              color="secondary"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default WorkflowListPage
