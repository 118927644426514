import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { Box, CircularProgress, Stack } from '@mui/material'

import { PublicView } from 'components/publicCase/PublicTopBanner'
import { useRecoilValue } from 'recoil'
import { publicPortalInfoState } from 'state/portalSettingStates'
import { type PublicCasesRawData } from 'components/publicCase/publicCaseTypes'
import PublicCaseAddMobile from 'components/publicCase/PublicCaseAddMobile'
import {
  publicCaseFilterInputsState,
  publicKeywordState,
  selectedPublicCaseCategoriesState,
  selectedPublicCaseStatusesState,
  selectedPublicPortalItemsState,
} from 'state/publicCaseListStates'
import { type CaseStatus } from 'components/case/caseConstants'
import useURLState from 'hooks/useURLState'
import { CustomTabPanel } from 'pages/PublicCaseAddPage'
import PublicCaseListMapMobile from 'components/publicCase/PublicCaseListMapMobile'
import PublicTopBannerMobile from 'components/publicCase/PublicTopBannerMobile'

const PublicCaseAddPageMobile: React.FC = () => {
  const [searchParams] = useSearchParams()
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const selectedPublicCaseStatuses = useRecoilValue(
    selectedPublicCaseStatusesState,
  )
  const keyword = useRecoilValue(publicKeywordState)
  const selectedPublicPortalItems = useRecoilValue(
    selectedPublicPortalItemsState,
  )
  const selectedPublicCaseCategories = useRecoilValue(
    selectedPublicCaseCategoriesState,
  )
  const [publicView, setPublicView] = useURLState<PublicView>(
    'publicView',
    PublicView.CASE_ADD,
  )
  const caseInputFields = useRecoilValue(publicCaseFilterInputsState)
  const portalId = searchParams.get('portalId')
  const { data: casesResponseData, isLoading } = useSWR<PublicCasesRawData>(
    portalId && publicView === PublicView.CASE_LIST
      ? `${
          process.env.REACT_APP_PUBLIC_API_PATH ?? ''
        }/portals/${portalId}/cases`
      : null,
  )

  const handleChangeView = (
    event: React.SyntheticEvent,
    newView: PublicView,
  ): void => {
    setPublicView(newView)
  }

  const otherFeedback = useMemo(() => {
    return caseInputFields?.categories.find((category) =>
      category.names.find(
        (name) => name.language === 'FI' && name.content === 'Muu palaute',
      ),
    )
  }, [caseInputFields])

  const filteredCases = useMemo(() => {
    const allCases = (casesResponseData?.cases ?? []).concat()
    allCases.sort(
      (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime(),
    )

    return allCases.filter((caseData) => {
      if (keyword) {
        const keywordLower = keyword.toLowerCase()
        if (
          !caseData.title?.toLowerCase().includes(keywordLower) &&
          !caseData.description.toLowerCase().includes(keywordLower) &&
          !caseData.location?.address.toLowerCase().includes(keywordLower) &&
          !caseData.location?.country?.toLowerCase().includes(keywordLower) &&
          !caseData.location?.postalCode?.toLowerCase().includes(keywordLower)
        ) {
          return false
        }
      }

      if (
        selectedPublicCaseStatuses.length > 0 &&
        !selectedPublicCaseStatuses.includes(caseData.status as CaseStatus)
      ) {
        return false
      }

      if (
        selectedPublicPortalItems.length > 0 &&
        !selectedPublicPortalItems.includes(caseData.item.id)
      ) {
        return false
      }

      if (
        selectedPublicCaseCategories.length > 0 &&
        otherFeedback &&
        caseData.category &&
        caseData.category.uncategorized &&
        caseData.category.id !== otherFeedback.id &&
        selectedPublicCaseCategories.includes(otherFeedback.id)
      ) {
        return true
      }

      if (
        selectedPublicCaseCategories.length > 0 &&
        caseData.category &&
        !selectedPublicCaseCategories.includes(caseData.category?.id)
      ) {
        return false
      }

      return true
    })
  }, [
    casesResponseData,
    selectedPublicCaseStatuses,
    keyword,
    selectedPublicPortalItems,
    selectedPublicCaseCategories,
  ])

  return (
    /* eslint-disable @typescript-eslint/no-misused-promises */
    <Box height={'100vh'} width="100%">
      <Stack height={'100%'} width="100%" overflow="hidden">
        <PublicTopBannerMobile
          onChangeView={handleChangeView}
          currentView={publicView}
        />

        <Box flexGrow={1} overflow="auto">
          <CustomTabPanel
            id="public-view-tabpanel-case-add"
            aria-labelledby="public-view-tab-case-add"
            selected={publicView === PublicView.CASE_ADD}
          >
            {portalId && (
              <PublicCaseAddMobile
                portalId={portalId}
                cases={casesResponseData?.cases}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel
            id="public-view-tabpanel-case-list"
            aria-labelledby="public-view-tab-case-list"
            selected={publicView === PublicView.CASE_LIST}
          >
            {isLoading && <CircularProgress />}

            {publicPortalInfo && (
              <PublicCaseListMapMobile
                cases={filteredCases}
                region={publicPortalInfo.mapConfiguration?.region}
                language={publicPortalInfo.defaultLanguage.toLowerCase()}
                zoom={publicPortalInfo.mapConfiguration?.zoomLevel}
                center={publicPortalInfo.mapConfiguration?.centre}
              />
            )}
          </CustomTabPanel>
        </Box>
      </Stack>
    </Box>
  )
}

export default PublicCaseAddPageMobile
