import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'

import MarkerIcon from 'assets/icons/marker.svg'
import MarkerSelectedIcon from 'assets/icons/marker_selected.svg'
import {
  getCaseStatusColor,
  getCaseStatusIcon,
} from 'components/case/caseUtils'
import { type CaseStatus, type VirtualPortalCaseStatus } from './caseConstants'

const Wrapper = styled(Box)<{
  status: CaseStatus | VirtualPortalCaseStatus
}>`
  position: relative;

  & .marker_svg__status {
    fill: ${({ status }) => getCaseStatusColor(status)};
  }

  & .marker_selected_svg__status-shadow {
    stroke: ${({ status }) => getCaseStatusColor(status)};
  }

  & .selected {
    top: 12px;
    left: 14px;

    & svg {
      fill: ${({ status }) => getCaseStatusColor(status)};
      font-size: 32px;
    }
  }
`

const StatusWrapper = styled(Box)`
  position: absolute;
  top: 12px;
  left: 13px;

  & svg {
    fill: ${({ theme }) => theme.palette.text.primary};

    font-size: 22px;
  }
`

type CaseMarkerProps = {
  selected?: boolean
  status: CaseStatus | VirtualPortalCaseStatus
}

const CaseMarker: React.FC<CaseMarkerProps> = ({
  selected = false,
  status,
  ...props
}) => {
  const CaseStatusIcon = getCaseStatusIcon(status)

  return (
    <Wrapper status={status} {...props}>
      {!selected && <MarkerIcon />}
      {selected && <MarkerSelectedIcon />}

      <StatusWrapper className={selected ? 'selected' : ''}>
        {CaseStatusIcon && <CaseStatusIcon />}
      </StatusWrapper>
    </Wrapper>
  )
}

export default CaseMarker
