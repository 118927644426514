import { useEffect, useState } from 'react'
import { Outlet, useSearchParams, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import { CircularProgress } from '@mui/material'

import { networkErrorState } from 'state/errorStates'
import useApi from 'hooks/useApi'
import { type NetworkError } from 'types'
import {
  currentLocaleState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import { ERROR_CODE_MESSAGE } from 'errorConstants'
import useRoute from 'hooks/useNavigate'
import { Path } from 'commonConstants'
import PublicCaseCreatedDialog from 'components/publicCase/PublicCaseCreatedDialog'
import {
  publicKeywordState,
  selectedPublicCaseCategoriesState,
  selectedPublicCaseStatusesState,
  selectedPublicPortalItemsState,
} from 'state/publicCaseListStates'
import { PUBLIC_CASE_STATUSES_DEFAULT } from 'components/publicCase/publicCaseConstants'

const AppPublicBootstrap: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const { goTo } = useRoute()
  const networkError = useRecoilValue(networkErrorState)
  const { sendGetRequest } = useApi()
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')
  const location = useLocation()

  const setCurrentLocale = useSetRecoilState(currentLocaleState)
  const [isPortalSettingLoading, setIsPortalSettingLoading] = useState(true)
  const setPublicPortalInfo = useSetRecoilState(publicPortalInfoState)
  const analytics = getAnalytics()
  const setSelectedCaseStatuses = useSetRecoilState(
    selectedPublicCaseStatusesState,
  )
  const setKeyword = useSetRecoilState(publicKeywordState)
  const setSelectedCaseCategories = useSetRecoilState(
    selectedPublicCaseCategoriesState,
  )
  const setSelectedPortalItems = useSetRecoilState(
    selectedPublicPortalItemsState,
  )

  const loadPortalSetting = async (portalId: string): Promise<void> => {
    try {
      setIsPortalSettingLoading(true)
      const response = await sendGetRequest(
        `${process.env.REACT_APP_PUBLIC_API_PATH ?? ''}/portals/${portalId}`,
      )
      const portalSettingData = await response?.json()
      setPublicPortalInfo({
        ...portalSettingData,
        id: portalId,
      })

      const userLanguage = localStorage.getItem('userLanguage')
      const urlLang = searchParams.get('lang')
      let currentLocale =
        urlLang?.toUpperCase() ?? portalSettingData.defaultLanguage

      if (
        userLanguage &&
        portalSettingData.supportedLanguages.includes(userLanguage)
      ) {
        currentLocale = userLanguage
      }

      setCurrentLocale(currentLocale)
      document.title = portalSettingData.name

      if (location.pathname === '/public') {
        goTo(Path.PUBLIC_CASE_ADD)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsPortalSettingLoading(false)

      const caseFiltersString = localStorage.getItem('caseFilters')
      if (caseFiltersString) {
        const caseFilters = JSON.parse(caseFiltersString)
        setSelectedCaseCategories(
          caseFilters?.[portalId]?.publicCategories ?? [],
        )
        setSelectedPortalItems(caseFilters?.[portalId]?.publicPortalItems ?? [])
        setKeyword(caseFilters?.[portalId]?.publicSearch ?? '')
        setSelectedCaseStatuses(
          caseFilters?.[portalId]?.publicStatuses ??
            PUBLIC_CASE_STATUSES_DEFAULT,
        )
      }
    }
  }

  useEffect(() => {
    if (portalId) {
      logEvent(analytics, 'web_public_page_opened')
      void loadPortalSetting(portalId)
    }
  }, [portalId, analytics])

  useEffect(() => {
    if (networkError) {
      try {
        const jsonData = JSON.parse(networkError) as NetworkError
        let errorTranslationKey = 'general.error.unknown_error'
        if (jsonData.code && !!ERROR_CODE_MESSAGE[jsonData.code]) {
          errorTranslationKey = ERROR_CODE_MESSAGE[jsonData.code]
        }
        enqueueSnackbar(formatMessage({ id: errorTranslationKey }), {
          variant: 'error',
        })
      } catch (e) {
        enqueueSnackbar(networkError, { variant: 'error' })
      }
    }
  }, [networkError])

  if (isPortalSettingLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <PublicCaseCreatedDialog />

      <Outlet />
    </>
  )
}

export default AppPublicBootstrap
