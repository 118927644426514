import { Period, CaseStatus } from 'components/case/caseConstants'

export type PublicCaseStatus =
  | CaseStatus.NEW
  | CaseStatus.IN_PROGRESS
  | CaseStatus.COMPLETED

export const PUBLIC_CASE_STATUSES_DEFAULT = [
  CaseStatus.NEW,
  CaseStatus.IN_PROGRESS,
  CaseStatus.COMPLETED,
]

export const PUBLIC_CASE_PERIODS_DEFAULT = Period.YEAR
