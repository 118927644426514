import { useCallback, useContext } from 'react'
import { getAuth, signOut } from 'firebase/auth'
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'

import { type BasicUserInfo } from 'types'
import AuthContext from 'context/AuthContext'
import { portalSettingState } from 'state/portalSettingStates'
import { hasUnsavedChangesState } from 'state/formStates'
import { currentPortalAccessSelector } from 'state/memberStates'
import { type PortalSectionAccesses } from 'components/role/roleTypes'
import { userAccessState } from 'state/userStates'

type UseUserReturn = {
  userInfo?: BasicUserInfo
  clearSession: () => void
  checkAccesses: (accesses?: PortalSectionAccesses) => boolean
}

const useMember = (): UseUserReturn => {
  const { clearAuthData } = useContext(AuthContext)
  const setPortalSetting = useSetRecoilState(portalSettingState)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [userAccess, setUserAccess] = useRecoilState(userAccessState)
  const currentPortalAccess = useRecoilValue(currentPortalAccessSelector)

  const checkAccesses = useCallback(
    (accesses?: PortalSectionAccesses): boolean => {
      if (userAccess?.userData.admin) {
        return true
      }

      if (!currentPortalAccess || !accesses) {
        return false
      }

      for (const [key, value] of Object.entries(accesses)) {
        const section =
          currentPortalAccess.accessibleSections[
            key as keyof PortalSectionAccesses
          ]

        if (!section || !value.includes(section)) {
          return false
        }
      }

      return true
    },
    [userAccess, currentPortalAccess],
  )

  const clearSession = useCallback((): void => {
    void signOut(getAuth())
    clearAuthData()
    setUserAccess(null)
    setPortalSetting(null)
    setHasUnsavedChanges(false)
  }, [])

  return {
    clearSession,
    checkAccesses,
  }
}

export default useMember
