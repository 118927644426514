import { useIntl } from 'react-intl'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import { type SelectChangeEvent } from '@mui/material/Select'
import {
  Box,
  MenuItem,
  Select,
  Stack,
  Input,
  Checkbox,
  InputLabel,
  FormControl,
  Typography,
  CircularProgress,
} from '@mui/material'

import CaseStatusIcon from 'components/case/CaseStatusIcon'
import CaseStatusTag from 'components/case/CaseStatusTag'

import { type CaseStatus } from 'components/case/caseConstants'
import { sortCategories } from 'utils/categoryUtils'
import usePortalSetting from 'hooks/usePortalSetting'
import CategorySelect from 'components/category/select/CategorySelect'
import { type CategoryBasic } from 'components/category/categoryTypes'
import { PUBLIC_CASE_STATUSES_DEFAULT } from 'components/publicCase/publicCaseConstants'
import {
  selectedPublicCaseCategoriesState,
  selectedPublicCaseStatusesState,
  publicCaseFilterInputsState,
  selectedPublicPortalItemsState,
} from 'state/publicCaseListStates'
import { SubHeader } from 'components/StyledComponents'

const FilterWrapper = styled(Stack)`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.background.paper};
  padding: 8px 16px;
`

type CaseFilterProps = {
  portalId: string
}

const CaseFilter: React.FC<CaseFilterProps> = ({ portalId }) => {
  const { formatMessage } = useIntl()
  const [selectedCaseStatuses, setSelectedCaseStatuses] = useRecoilState(
    selectedPublicCaseStatusesState,
  )

  const [selectedCaseCategories, setSelectedCaseCategories] = useRecoilState(
    selectedPublicCaseCategoriesState,
  )
  const [selectedPortalItems, setSelectedPortalItems] = useRecoilState(
    selectedPublicPortalItemsState,
  )
  const caseFilterInputs = useRecoilValue(publicCaseFilterInputsState)
  const { getLocalizedContent, persistFilters } = usePortalSetting()

  const handlePortalItemChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event

    if (Array.isArray(value)) {
      const results = value.includes('') ? [] : value
      setSelectedPortalItems(results)
      persistFilters('publicPortalItems', results)
    }
  }

  const handleStatusChange = (event: SelectChangeEvent<CaseStatus[]>): void => {
    const {
      target: { value },
    } = event
    if (Array.isArray(value)) {
      setSelectedCaseStatuses(value.sort())
      persistFilters('publicStatuses', value)
    }
  }

  const handleCategoryChange = (categoryIds: string[]): void => {
    setSelectedCaseCategories(categoryIds)
    persistFilters('publicCategories', categoryIds)
  }

  if (!caseFilterInputs) {
    return <CircularProgress />
  }

  return (
    <FilterWrapper width={'100%'} direction={'row'} marginY={1}>
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        flexGrow={1}
        flexWrap={'wrap'}
      >
        <Stack
          flexGrow={1}
          direction="row"
          alignItems="center"
          spacing={2}
          flexWrap={'wrap'}
        >
          <SubHeader>
            {formatMessage({ id: 'consumer_page.header.case_list' })}
          </SubHeader>
        </Stack>

        <FormControl variant="standard">
          <CategorySelect
            label={formatMessage({
              id: 'consumer_page.label.category',
            })}
            value={selectedCaseCategories}
            onChange={handleCategoryChange}
            categories={sortCategories<CategoryBasic>(
              caseFilterInputs?.categories ?? [],
            )}
            renderValue={
              selectedCaseCategories.length > 0
                ? formatMessage(
                    { id: 'case_filter.option.n_selected' },
                    { count: selectedCaseCategories.length },
                  )
                : formatMessage({ id: 'consumer_page.category.all_category' })
            }
          />
        </FormControl>

        {caseFilterInputs && caseFilterInputs.portalItems.length > 1 && (
          <FormControl variant="standard">
            <InputLabel id="case-item-select-label" shrink>
              {formatMessage({
                id: 'case_filter.label.portal_item',
              })}
            </InputLabel>
            <Select
              labelId="case-item-select-label"
              multiple
              size="small"
              value={selectedPortalItems}
              displayEmpty
              onChange={handlePortalItemChange}
              input={<Input />}
              renderValue={(selected) =>
                selected.length > 0
                  ? formatMessage(
                      { id: 'case_filter.option.n_selected' },
                      { count: selected.length },
                    )
                  : formatMessage({ id: 'case_filter.portal_item.all_item' })
              }
            >
              <MenuItem value="">
                <Checkbox checked={selectedPortalItems.length === 0} />
                <Typography marginLeft={1}>
                  {formatMessage({ id: 'case_filter.portal_item.all_item' })}
                </Typography>
              </MenuItem>
              {caseFilterInputs.portalItems.map((portalItem) => (
                <MenuItem key={portalItem.id} value={portalItem.id}>
                  <Checkbox
                    checked={selectedPortalItems.includes(portalItem.id)}
                  />
                  <Typography marginLeft={1}>
                    {getLocalizedContent(portalItem.names)}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl variant="standard">
          <InputLabel id="case-status-select-label">
            {formatMessage({
              id: 'case_filter.label.status',
            })}
          </InputLabel>
          <Select
            labelId="case-status-select-label"
            multiple
            size="small"
            value={selectedCaseStatuses}
            onChange={handleStatusChange}
            input={<Input />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <CaseStatusIcon key={value} status={value} size="small" />
                ))}
              </Box>
            )}
            sx={{
              width: 120,
            }}
          >
            {PUBLIC_CASE_STATUSES_DEFAULT.map((caseStatus) => (
              <MenuItem key={caseStatus} value={caseStatus}>
                <Checkbox checked={selectedCaseStatuses.includes(caseStatus)} />
                <CaseStatusTag status={caseStatus} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </FilterWrapper>
  )
}

export default CaseFilter
