import { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useIntl } from 'react-intl'
import { type XYCoord, useDrag, useDrop } from 'react-dnd'
import {
  Button,
  Chip,
  Divider,
  Stack,
  IconButton,
  Typography,
  Box,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PhoneIcon from '@mui/icons-material/Phone'
import AddressIcon from '@mui/icons-material/LocationOn'
import EmailIcon from '@mui/icons-material/Email'
import WebsiteIcon from '@mui/icons-material/Language'
import EditIcon from '@mui/icons-material/Edit'

import { type ContactFormField } from 'components/item/itemTypes'
import { SubContentWrapper, SubSubHeader } from 'components/StyledComponents'
import { ContactDetailType } from 'components/item/itemConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import { sortedSupportLanguagesSelector } from 'state/portalSettingStates'
import ContactEditForm from 'components/item/ContactEditForm'

type ContactCardProps = {
  contact: ContactFormField
  index: number
  selectedContactsIds: Set<string>
  defaultLanguage: string
  onSaveContact: (data: ContactFormField, contactId: string) => void
  onEditContact: (contactId: string) => void
  onOpenDeleteContactConfirmDialog: (contactId: string) => void
  onCloseContactForm: (contactId: string) => void
  onMoveContact: (dragIndex: number, hoverIndex: number) => void
}

type Item = {
  id: string
  index: number
}

const ContactCard: React.FC<ContactCardProps> = ({
  contact,
  index,
  defaultLanguage,
  onSaveContact,
  onEditContact,
  selectedContactsIds,
  onCloseContactForm,
  onOpenDeleteContactConfirmDialog,
  onMoveContact,
}) => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLDivElement>(null)
  const { formatPhoneNumber } = usePortalSetting()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const [openContactsTranslation, setOpenContactsTranslation] = useState<
    Set<string>
  >(new Set())

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'ContactCard',
      item: { id: contact.id, index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [contact, index],
  )

  const [, drop] = useDrop(
    () => ({
      accept: 'ContactCard',
      hover(item: Item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) {
          return
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        const clientOffset = monitor.getClientOffset()

        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        onMoveContact(dragIndex, hoverIndex)

        item.index = hoverIndex
      },
    }),
    [index],
  )

  drag(drop(ref))

  return (
    <Stack ref={ref} style={{ opacity, cursor: 'move' }}>
      {selectedContactsIds.has(contact.id) && (
        <ContactEditForm
          contact={contact}
          onSave={(data: ContactFormField) => {
            onSaveContact(data, contact.id)
          }}
          onClose={() => {
            onCloseContactForm(contact.id)
          }}
        />
      )}

      {!selectedContactsIds.has(contact.id) &&
        !openContactsTranslation.has(contact.id) && (
          <SubContentWrapper direction="row" spacing={1}>
            <Stack spacing={1} flex={1}>
              <SubSubHeader>{contact.names[defaultLanguage]}</SubSubHeader>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                {contact.descriptions[defaultLanguage]}
              </Typography>

              {supportLanguages.length > 1 && (
                <Box>
                  <Button
                    variant="text"
                    size="small"
                    endIcon={<ArrowDownIcon />}
                    onClick={(): void => {
                      setOpenContactsTranslation((prev) => {
                        prev.add(contact.id)
                        return new Set(prev)
                      })
                    }}
                  >
                    {formatMessage({
                      id: 'alert_list.button.show_translations',
                    })}
                  </Button>
                </Box>
              )}
            </Stack>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Stack flex={1} spacing={1}>
              {contact.details.map((info, index) => (
                <Stack key={index} direction="row" spacing={1}>
                  {info.type === ContactDetailType.PHONE && (
                    <PhoneIcon fontSize="small" />
                  )}
                  {info.type === ContactDetailType.EMAIL && (
                    <EmailIcon fontSize="small" />
                  )}
                  {info.type === ContactDetailType.WEBSITE && (
                    <WebsiteIcon fontSize="small" />
                  )}
                  {info.type === ContactDetailType.ADDRESS && (
                    <AddressIcon fontSize="small" />
                  )}
                  <Typography variant="body2">
                    {info.type === ContactDetailType.PHONE
                      ? formatPhoneNumber(info.texts[defaultLanguage])
                      : info.texts[defaultLanguage]}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack>
              <IconButton
                aria-label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                size="small"
                onClick={() => {
                  onEditContact(contact.id)
                }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
              <IconButton
                aria-label={formatMessage({
                  id: 'general.icon_button.delete',
                })}
                size="small"
                onClick={() => {
                  onOpenDeleteContactConfirmDialog(contact.id)
                }}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </SubContentWrapper>
        )}

      {!selectedContactsIds.has(contact.id) &&
        openContactsTranslation.has(contact.id) && (
          <SubContentWrapper spacing={1}>
            <Stack direction="row">
              <Stack spacing={2} width="100%">
                {supportLanguages.map((language) => (
                  <Stack key={language} spacing={1}>
                    <Box>
                      <Chip label={language} size="small" />
                    </Box>
                    <Stack direction="row" spacing={1}>
                      <Stack spacing={1} flex={1}>
                        <SubSubHeader>{contact.names[language]}</SubSubHeader>
                        <Typography
                          variant="body2"
                          sx={{ whiteSpace: 'pre-line' }}
                        >
                          {contact.descriptions[language]}
                        </Typography>
                      </Stack>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Stack flex={1} spacing={1}>
                        {contact.details.map((info, index) => (
                          <Stack key={index} direction="row" spacing={1}>
                            {info.type === ContactDetailType.PHONE && (
                              <PhoneIcon fontSize="small" />
                            )}
                            {info.type === ContactDetailType.EMAIL && (
                              <EmailIcon fontSize="small" />
                            )}
                            {info.type === ContactDetailType.WEBSITE && (
                              <WebsiteIcon fontSize="small" />
                            )}
                            {info.type === ContactDetailType.ADDRESS && (
                              <AddressIcon fontSize="small" />
                            )}
                            <Typography variant="body2">
                              {info.type === ContactDetailType.PHONE
                                ? formatPhoneNumber(info.texts[language])
                                : info.texts[language]}
                            </Typography>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                ))}
              </Stack>

              <Stack>
                <IconButton
                  aria-label={formatMessage({
                    id: 'general.icon_button.edit',
                  })}
                  size="small"
                  onClick={() => {
                    onEditContact(contact.id)
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  aria-label={formatMessage({
                    id: 'general.icon_button.delete',
                  })}
                  size="small"
                  onClick={() => {
                    onOpenDeleteContactConfirmDialog(contact.id)
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Stack>
            </Stack>

            <Box>
              <Button
                variant="text"
                size="small"
                endIcon={<ArrowUpIcon />}
                onClick={() => {
                  setOpenContactsTranslation((prev) => {
                    prev.delete(contact.id)
                    return new Set(prev)
                  })
                }}
              >
                {formatMessage({
                  id: 'alert_list.button.hide_translations',
                })}
              </Button>
            </Box>
          </SubContentWrapper>
        )}
    </Stack>
  )
}

export default ContactCard
