import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import { Box, Button, CircularProgress, Stack, Tooltip } from '@mui/material'
import {
  GridActionsCellItem,
  type GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import BlockIcon from '@mui/icons-material/Block'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import SmallErrorIcon from '@mui/icons-material/Error'

import { DataTable, MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyMemberIcon from 'assets/icons/empty_member.svg'
import useRoute from 'hooks/useNavigate'
import { type Member, type TeamBasic } from 'types'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { Path } from '../commonConstants'
import { type RoleBasic } from 'components/role/roleTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import GridActionItem from 'components/helpers/GridActionItem'

const MembersWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`

const MemberListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const {
    data: membersData,
    isLoading,
    mutate: mutateMembers,
  } = useSWR<Member[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/members`
      : null,
  )
  const { goTo } = useRoute()
  const dataTableRef = useGridApiRef()
  const { enqueueSnackbar } = useSnackbar()
  const { sendPostRequest } = useApi()
  const { checkAccesses } = useMember()

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const columnsData: GridColDef[] = [
      {
        field: 'firstName',
        headerName: formatMessage({ id: 'member_edit.label.first_name' }),
        flex: 1,
        valueGetter: (value, row) => row.firstName,
        renderCell: (params) => {
          return params.row.userPaired ? (
            params.row.firstName
          ) : (
            <Stack direction="row" spacing={1}>
              <Tooltip
                title={formatMessage({ id: 'member_edit.error.user_removed' })}
              >
                <SmallErrorIcon fontSize="small" color="error" />
              </Tooltip>

              {params.row.firstName}
            </Stack>
          )
        },
      },
      {
        field: 'lastName',
        headerName: formatMessage({ id: 'member_edit.label.last_name' }),
        flex: 1,
        renderCell: (params) => params.row.lastName,
      },
      {
        field: 'jobTitle',
        headerName: formatMessage({ id: 'member_edit.label.job_title' }),
        flex: 2,
        renderCell: (params) => params.row.jobTitle,
      },
      {
        field: 'email',
        headerName: formatMessage({ id: 'member_list.label.email' }),
        flex: 3,
        renderCell: (params) => params.row.email,
      },
      {
        field: 'role',
        headerName: formatMessage({ id: 'member_list.label.role' }),
        flex: 1,
        valueGetter: (value, row) =>
          row.roles.map((role: RoleBasic) => role.name).join(', '),
        renderCell: (params) =>
          params.row.roles.map((role: RoleBasic) => role.name).join(', '),
      },
      {
        field: 'team',
        headerName: formatMessage({ id: 'member_list.label.team' }),
        flex: 1,
        valueGetter: (value, row) =>
          row.teams.map((team: TeamBasic) => team.name).join(', '),
        renderCell: (params) =>
          params.row.teams.map((team: TeamBasic) => team.name).join(', '),
      },
    ]

    if (checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] })) {
      columnsData.push({
        field: 'action',
        type: 'actions',
        getActions: (params) => {
          const actions = []

          if (params.row._operations.canEdit) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<EditIcon />}
                label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  handleRowClick(params.row)
                }}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canDeactivate) {
            actions.push(
              <GridActionItem
                key={params.id}
                icon={<BlockIcon />}
                label={formatMessage({
                  id: 'member_list.menu.deactivate',
                })}
                confirmDialogTitle={formatMessage({
                  id: 'member_list.deactivate_confirm.title',
                })}
                confirmDialogContent={formatMessage({
                  id: 'member_list.deactivate_confirm.content',
                })}
                confirmButtonText={formatMessage({
                  id: 'member_list.menu.deactivate',
                })}
                onConfirm={async (): Promise<void> => {
                  await handleConfirmedUpdateActive(
                    params.row.id as string,
                    false,
                  )
                }}
                closeMenuOnClick={false}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canActivate) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                icon={<CheckIcon />}
                label={formatMessage({
                  id: 'member_list.menu.activate',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  void handleConfirmedUpdateActive(params.row.id, true)
                }}
                showInMenu
              />,
            )
          }
          return actions
        },
      })
    }

    return columnsData
  }, [portalSetting])

  const handleRowClick = useCallback(
    (row: Member): void => {
      if (
        portalSetting &&
        checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] })
      ) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members/${row.id}`,
          row,
          false,
        )

        goTo(`${Path.MEMBERS_EDIT}/${row.id}`)
      }
    },
    [portalSetting],
  )

  const handleConfirmedUpdateActive = useCallback(
    async (memberId: string, active: boolean): Promise<void> => {
      if (portalSetting) {
        try {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/members/${memberId}:${active ? 'activate' : 'deactivate'}`,
          )

          if (membersData?.length) {
            const newMembersData = membersData.map((member) => {
              if (member.id === memberId) {
                return {
                  ...member,
                  active,
                }
              }

              return member
            })

            await mutateMembers(newMembersData)
          }

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
        } catch (error) {
          console.error(error)
        }
      }
    },
    [portalSetting, membersData],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>{formatMessage({ id: 'member_list.header' })}</MainHeader>
        </Box>

        {membersData &&
          membersData?.length > 0 &&
          checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.MEMBERS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'member_list.button.create_member',
              })}
            </Button>
          )}
      </Stack>

      {membersData?.length === 0 &&
        checkAccesses({ [PortalSection.MEMBERS]: [FeatureAccess.WRITE] }) && (
          <MembersWrapper
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Stack textAlign="center" spacing={2} alignItems="center">
              <EmptyMemberIcon />
              <Box width="100%">
                <SubHeader>
                  {formatMessage({
                    id: 'member_list.label.start_creating_member',
                  })}
                </SubHeader>
              </Box>
              <Box width="100%">
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    goTo(Path.MEMBERS_ADD)
                  }}
                  color="secondary"
                >
                  {formatMessage({
                    id: 'member_list.button.create_member',
                  })}
                </Button>
              </Box>
            </Stack>
          </MembersWrapper>
        )}

      <Box flexGrow={1} overflow="hidden">
        {isLoading && <CircularProgress />}

        {!!membersData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={membersData ?? []}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: 'firstName', sort: 'asc' }],
              },
            }}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            getRowHeight={() => 'auto'}
            getRowClassName={(params) =>
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              `${params.row.active ? 'active' : 'inactive'}`
            }
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

export default MemberListPage
