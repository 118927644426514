import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import {
  GridActionsCellItem,
  type GridColDef,
  type GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid'
import {
  Stack,
  CircularProgress,
  Box,
  Button,
  Tooltip,
  Typography,
  Chip,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import {
  DataTable,
  InfoText,
  MainHeader,
  SubHeader,
} from 'components/StyledComponents'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import usePortalSetting from 'hooks/usePortalSetting'
import { FileType, Path } from 'commonConstants'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import useRoute from 'hooks/useNavigate'
import EmptyOfferIcon from 'assets/icons/empty_offer.svg'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import OfferPreviewDialog from 'components/offer/OfferPreviewDialog'
import { convertLocalizedStringToData } from 'utils/stringUtils'
import { EmptyImageIcon } from 'components/icons/Icons'
import GridActionItem from 'components/helpers/GridActionItem'
import { OfferStatus } from 'components/offer/offerConstants'
import { type Offer } from 'components/offer/offerTypes'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const ViewTabsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  & .MuiButton-endIcon .counter {
    padding: 0px 8px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-text {
    & .counter {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

const OfferListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const dataTableRef = useGridApiRef()
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()
  const { getLocalizedContent, formatDate } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendDeleteRequest, sendPostRequest } = useApi()
  const [currentView, setCurrentView] = useState<OfferStatus | 'logs'>(
    OfferStatus.ACTIVATED,
  )
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null)

  const {
    data: offersData,
    isLoading,
    mutate: mutateOffers,
  } = useSWR<Offer[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/offers`
      : null,
  )

  useEffect(() => {
    setSortModel([
      {
        field: currentView === OfferStatus.ACTIVATED ? 'activated' : 'updated',
        sort: 'desc',
      },
    ])
  }, [currentView])

  const columns = useMemo((): GridColDef[] => {
    if (!portalSetting) {
      return []
    }

    const columnsData: GridColDef[] = [
      {
        field: 'image',
        headerName: '',
        width: 100,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
          return params.row.url ? (
            <ResourceWrapper
              size={70}
              url={params.row.url}
              format={FileType.IMAGE}
              imageSize="cover"
            />
          ) : (
            <ResourceWrapper
              size={70}
              format={FileType.IMAGE}
              imageSize="cover"
              hasBorder={false}
            >
              <EmptyImageIcon sx={{ width: 60, height: 49 }} />
            </ResourceWrapper>
          )
        },
      },
      {
        field: 'title',
        headerName: formatMessage({ id: 'offer_list.label.title' }),
        flex: 2,
        valueGetter: (value, row) => getLocalizedContent(row.titles),
        renderCell: (params) => (
          <Stack>
            {getLocalizedContent(params.row.titles)}{' '}
            <Box>
              <Chip
                label={getLocalizedContent(params.row.item?.names)}
                size="small"
              />
            </Box>
          </Stack>
        ),
      },

      {
        field: 'singleUse',
        headerName: formatMessage({ id: 'offer_list.label.usage' }),
        flex: 1,
        renderCell: (params) =>
          formatMessage({
            id: params.row.singleUse
              ? 'offer_list.usage.one_time'
              : 'offer_list.usage.multiple_times',
          }),
      },
    ]

    if (currentView === OfferStatus.ACTIVATED) {
      columnsData.push(
        {
          field: 'timesActivated',
          headerName: formatMessage({
            id: 'offer_list.label.used',
          }),
          flex: 1,
        },
        {
          field: 'activated',
          headerName: formatMessage({
            id: 'offer_list.label.activated_at',
          }),
          flex: 1,
          disableColumnMenu: true,
          renderCell: (params) => {
            const datetime = formatDate(params.value).split(' ')
            return (
              <Stack>
                <Typography variant="body1">{datetime[0]}</Typography>
                <InfoText>{datetime[1]}</InfoText>
              </Stack>
            )
          },
        },
      )
    }

    columnsData.push({
      field: 'expiry',
      headerName: formatMessage({ id: 'offer_list.label.expiry_date' }),
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        const datetime = formatDate(params.value).split(' ')
        return (
          <Stack>
            <Typography variant="body1">{datetime[0]}</Typography>
            <InfoText>{datetime[1]}</InfoText>
          </Stack>
        )
      },
    })

    if (checkAccesses({ [PortalSection.NEWS_POSTS]: [FeatureAccess.WRITE] })) {
      columnsData.push({
        field: 'action',
        type: 'actions',
        getActions: (params) => {
          const actions = []

          if (params.row._operations.canEdit) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                label={formatMessage({
                  id: 'general.icon_button.edit',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  handleEditOffer(params.row)
                }}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canArchive) {
            actions.push(
              <GridActionItem
                key={params.id}
                label={formatMessage({
                  id: 'offer_list.menu.archive',
                })}
                confirmDialogTitle={formatMessage({
                  id: 'offer_list.confirm.archive.title',
                })}
                confirmDialogContent={formatMessage({
                  id: 'offer_list.confirm.archive.content',
                })}
                confirmButtonText={formatMessage({
                  id: 'offer_list.button.archive',
                })}
                onConfirm={async (): Promise<void> => {
                  await handleArchiveOffer(params.row.id)
                }}
                closeMenuOnClick={false}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canRemove) {
            actions.push(
              <GridActionItem
                key={params.id}
                label={formatMessage({
                  id: 'general.icon_button.delete',
                })}
                confirmDialogTitle={formatMessage({
                  id: 'offer_list.confirm.delete.title',
                })}
                confirmDialogContent={formatMessage({
                  id: 'offer_list.confirm.delete.content',
                })}
                confirmButtonText={formatMessage({
                  id: 'general.icon_button.delete',
                })}
                onConfirm={async (): Promise<void> => {
                  await deleteOffer(params.row.id)
                }}
                closeMenuOnClick={false}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canActivate) {
            actions.push(
              <GridActionItem
                key={params.id}
                label={formatMessage({
                  id: 'offer_list.menu.activate',
                })}
                confirmDialogTitle={formatMessage({
                  id: 'offer_list.confirm.activate.title',
                })}
                confirmDialogContent={formatMessage({
                  id: 'offer_list.confirm.activate.content',
                })}
                confirmButtonText={formatMessage({
                  id: 'offer_list.button.activate',
                })}
                onConfirm={async (): Promise<void> => {
                  await handleActivateOffer(params.row.id)
                }}
                closeMenuOnClick={false}
                showInMenu
              />,
            )
          }

          if (params.row._operations.canCopy) {
            actions.push(
              <GridActionsCellItem
                key={params.id}
                label={formatMessage({
                  id: 'offer_list.menu.copy_to_draft',
                })}
                closeMenuOnClick={false}
                onClick={() => {
                  handleCopy(params.row)
                }}
                showInMenu
              />,
            )
          }

          return actions
        },
      })
    }

    return columnsData
  }, [portalSetting, currentView])

  const deleteOffer = useCallback(
    async (offerId: string): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        await sendDeleteRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/offers/${offerId}`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutateOffers()
      } catch (error) {
        console.error(error)
      }
    },
    [portalSetting],
  )

  const handleArchiveOffer = useCallback(
    async (offerId: string): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/offers/${offerId}:archive`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutateOffers()
      } catch (error) {
        console.error(error)
      }
    },
    [portalSetting],
  )

  const handleActivateOffer = useCallback(
    async (offerId: string): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/offers/${offerId}:activate`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutateOffers()
      } catch (error) {
        console.error(error)
      }
    },
    [portalSetting],
  )

  const activatedOffers = useMemo(
    () =>
      offersData?.filter((offer) => offer.status === OfferStatus.ACTIVATED) ??
      [],
    [offersData],
  )

  const draftOffers = useMemo(
    () =>
      offersData?.filter((offer) => offer.status === OfferStatus.DRAFT) ?? [],
    [offersData],
  )

  const archivedOffers = useMemo(
    () =>
      offersData?.filter(
        (offer) =>
          offer.status === OfferStatus.ARCHIVED ||
          offer.status === OfferStatus.EXPIRED,
      ) ?? [],
    [offersData],
  )

  const currentOffers = useMemo(
    () =>
      currentView === OfferStatus.ACTIVATED
        ? activatedOffers
        : currentView === OfferStatus.DRAFT
        ? draftOffers
        : currentView === OfferStatus.ARCHIVED
        ? archivedOffers
        : offersData ?? [],
    [currentView, activatedOffers, draftOffers, archivedOffers, offersData],
  )

  const handleEditOffer = useCallback(
    (offer: Offer): void => {
      if (portalSetting) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/offers/${offer.id}`,
          offer,
          false,
        )

        goTo(`${Path.OFFERS_EDIT}/${offer.id}`)
      }
    },
    [portalSetting],
  )

  const handleCopy = useCallback(
    (offer: Offer): void => {
      if (portalSetting) {
        void mutate(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/offers/${offer.id}`,
          offer,
          false,
        )

        goTo(`${Path.OFFERS_EDIT}/${offer.id}?isCopy=true`)
      }
    },
    [portalSetting],
  )

  const handleClosePreviousDialog = (): void => {
    setIsPreviewDialogOpen(false)
  }

  const handleRowClick = useCallback((row: Offer): void => {
    setSelectedOffer(row)
    setIsPreviewDialogOpen(true)
  }, [])

  const handleSortModelChange = (newSortModel: GridSortModel): void => {
    setSortModel(newSortModel)
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'offer_list.header' })}
            <Tooltip
              title={formatMessage({
                id: 'offer_list.label.creating_offer_help_text',
              })}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {offersData &&
          offersData?.length > 0 &&
          checkAccesses({
            [PortalSection.OFFERS]: [FeatureAccess.WRITE],
          }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.OFFERS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({
                id: 'offer_list.button.create_offer',
              })}
            </Button>
          )}
      </Stack>

      <ViewTabsWrapper direction="row" padding={1} spacing={1}>
        <Button
          size="small"
          variant={currentView === OfferStatus.ACTIVATED ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(OfferStatus.ACTIVATED)
          }}
          endIcon={
            activatedOffers.length > 0 && (
              <Box className="counter">{activatedOffers.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'offer_list.view.activated' })}
        </Button>
        <Button
          size="small"
          variant={currentView === OfferStatus.DRAFT ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(OfferStatus.DRAFT)
          }}
          endIcon={
            draftOffers.length > 0 && (
              <Box className="counter">{draftOffers.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'offer_list.view.draft' })}
        </Button>
        <Button
          size="small"
          variant={currentView === OfferStatus.ARCHIVED ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(OfferStatus.ARCHIVED)
          }}
          endIcon={
            archivedOffers.length > 0 && (
              <Box className="counter">{archivedOffers.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'offer_list.view.archived' })}
        </Button>
      </ViewTabsWrapper>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {offersData &&
          offersData.length === 0 &&
          checkAccesses({
            [PortalSection.OFFERS]: [FeatureAccess.WRITE],
          }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyOfferIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'offer_list.label.start_creating_offer',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'offer_list.label.creating_offer_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      goTo(Path.OFFERS_ADD)
                    }}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'offer_list.button.create_offer',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {!!offersData?.length && !isLoading && (
          <DataTable
            apiRef={dataTableRef}
            rows={currentOffers}
            columns={columns}
            sortModel={sortModel}
            getRowHeight={() => 'auto'}
            onRowClick={(param) => {
              handleRowClick(param.row)
            }}
            onSortModelChange={handleSortModelChange}
            hideFooter
            slotProps={{
              row: {
                tabIndex: 0,
                onKeyDown: (event) => {
                  if (event.key === 'Enter') {
                    const row = event.target as HTMLTableRowElement
                    row.click()
                  }
                },
              },
            }}
          />
        )}
      </Stack>

      <OfferPreviewDialog
        isOpen={isPreviewDialogOpen}
        onClose={handleClosePreviousDialog}
        imageUrl={selectedOffer?.url}
        titles={convertLocalizedStringToData(selectedOffer?.titles)}
        descriptions={convertLocalizedStringToData(selectedOffer?.descriptions)}
        singleUse={selectedOffer?.singleUse}
        expiry={selectedOffer?.expiry}
      />
    </Stack>
  )
}

export default OfferListPage
