import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  TextField,
  Popover,
  Box,
  Divider,
  Button,
  InputAdornment,
  type TextFieldVariants,
} from '@mui/material'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import CategoryFilterTree from 'components/category/filter/CategoryFilterTree'
import { type CategoryBasic } from 'components/category/categoryTypes'

type CategorySelectProps = {
  required?: boolean
  variant?: TextFieldVariants
  label: string
  value: string[]
  onChange: (value: string[]) => void
  categories?: CategoryBasic[]
  renderValue: string
}

const CategorySelect: React.FC<CategorySelectProps> = ({
  required = false,
  variant = 'standard',
  label,
  value,
  onChange,
  categories = [],
  renderValue,
}) => {
  const { formatMessage } = useIntl()
  const [isCategoryPopOpen, setIsCategoryPopOpen] = useState(false)
  const [categoryPopEl, setCategoryPopEl] = useState<HTMLInputElement | null>(
    null,
  )

  const handleOpenCategoryPop = (
    event: React.MouseEvent<HTMLInputElement>,
  ): void => {
    setCategoryPopEl(event.currentTarget)
    setIsCategoryPopOpen(true)
  }

  const handleCloseCategoryPop = (): void => {
    setCategoryPopEl(null)
    setIsCategoryPopOpen(false)
  }

  const handleCategoryChange = (
    categoryIds: string[],
    checked: boolean,
  ): void => {
    if (checked) {
      const results = [...value, ...categoryIds]
      onChange(Array.from(new Set(results)))
    } else {
      onChange(value.filter((id) => !categoryIds.includes(id)))
    }
  }

  const handleClearCategories = (): void => {
    onChange([])
  }

  return (
    <>
      <TextField
        required={required}
        aria-describedby="category-popover"
        label={label}
        variant={variant}
        value={renderValue}
        slotProps={{
          inputLabel: { shrink: true },
          input: {
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {isCategoryPopOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </InputAdornment>
            ),
            sx: {
              cursor: 'pointer',
              paddingRight: variant !== 'standard' ? '8px' : 0,
            },
          },
        }}
        onClick={handleOpenCategoryPop}
        size="small"
      />
      <Popover
        id="category-popover"
        open={isCategoryPopOpen}
        anchorEl={categoryPopEl}
        onClose={handleCloseCategoryPop}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: categoryPopEl?.clientWidth,
            },
          },
        }}
      >
        <Box
          padding={1}
          sx={{
            maxHeight: 'calc(50vh)',
            overflow: 'auto',
          }}
        >
          <CategoryFilterTree
            categories={categories}
            selectedCategoryIds={value}
            onCategoryChange={handleCategoryChange}
          />
        </Box>
        <Box padding={1}>
          <Divider />
          <Button
            variant="text"
            onClick={handleClearCategories}
            sx={{ marginLeft: 1 }}
          >
            {formatMessage({
              id: 'case_filter.category.button.clear',
            })}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default CategorySelect
