import ItemAccommodationIcon from 'assets/icons/item_accommodation.svg'
import ItemArtsIcon from 'assets/icons/item_arts.svg'
import ItemBeautyIcon from 'assets/icons/item_beauty.svg'
import ItemEducationIcon from 'assets/icons/item_education.svg'
import ItemFoodIcon from 'assets/icons/item_food.svg'
import ItemHealthIcon from 'assets/icons/item_health.svg'
import ItemPublicIcon from 'assets/icons/item_public.svg'
import ItemRepairIcon from 'assets/icons/item_repair.svg'
import ItemShoppingIcon from 'assets/icons/item_shopping.svg'
import ItemSportsIcon from 'assets/icons/item_sports.svg'
import ItemTransportIcon from 'assets/icons/item_transport.svg'

export const ITEM_COUNTRY_CODE_MAX_LENGTH = 2
export const ITEM_NAME_MAX_LENGTH = 50
export const ITEM_WEBSITE_MAX_LENGTH = 1000
export const ITEM_DESCRIPTION_MAX_LENGTH = 1000
export const ITEM_FEED_URL_MAX_LENGTH = 1000
export const ITEM_REPORT_DISCLAIMER_MAX_LENGTH = 1000
export const ITEM_ZIP_CODES_MAX_LENGTH = 5000
export const ITEM_PUBLIC_RETENTION_DAYS = 30
export const ITEM_CONTACT_NAME_MAX_LENGTH = 100
export const ITEM_CONTACT_DESCRIPTION_MAX_LENGTH = 1000

// export enum ItemPrivacyMode {
//   COMMUNITY_MODE_PRIVATE_REPORTING = 'COMMUNITY_MODE_PRIVATE_REPORTING',
//   COMMUNITY_MODE = 'COMMUNITY_MODE',
//   OPEN_MODE_PRIVATE_REPORTING = 'OPEN_MODE_PRIVATE_REPORTING',
//   OPEN_MODE = 'OPEN_MODE',
// }

export enum ItemNewsSource {
  RSS = 'RSS',
  NEWS_POSTS = 'NEWS_POSTS',
}

export enum ContactDetailType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  WEBSITE = 'WEBSITE',
  ADDRESS = 'ADDRESS',
}

export enum ItemType {
  LOCATION_BASED = 'LOCATION_BASED',
  ZIP_CODE_BASED = 'ZIP_CODE_BASED',
}

export enum BusinessSegment {
  FOOD_AND_DRINK = 'FOOD_AND_DRINK',
  SHOPPING_AND_RETAIL = 'SHOPPING_AND_RETAIL',
  ARTS_CULTURE_AND_ENTERTAINMENT = 'ARTS_CULTURE_AND_ENTERTAINMENT',
  ACCOMMODATION_AND_TRAVEL = 'ACCOMMODATION_AND_TRAVEL',
  SPORTS_ACTIVITIES_AND_LEISURE = 'SPORTS_ACTIVITIES_AND_LEISURE',
  BEAUTY_AND_WELLNESS = 'BEAUTY_AND_WELLNESS',
  HEALTH_AND_MEDICINE = 'HEALTH_AND_MEDICINE',
  PUBLIC_AND_PROFESSIONAL_SERVICE = 'PUBLIC_AND_PROFESSIONAL_SERVICE',
  REPAIR_AND_MAINTENANCE = 'REPAIR_AND_MAINTENANCE',
  EDUCATION_AND_LEARNING = 'EDUCATION_AND_LEARNING',
  TAXI_AND_TRANSPORT = 'TAXI_AND_TRANSPORT',
}

export const BUSINESS_SEGMENT_ICONS: Record<
  BusinessSegment,
  React.FunctionComponent<React.SVGAttributes<SVGElement>>
> = {
  [BusinessSegment.FOOD_AND_DRINK]: ItemFoodIcon,
  [BusinessSegment.SHOPPING_AND_RETAIL]: ItemShoppingIcon,
  [BusinessSegment.ARTS_CULTURE_AND_ENTERTAINMENT]: ItemArtsIcon,
  [BusinessSegment.ACCOMMODATION_AND_TRAVEL]: ItemAccommodationIcon,
  [BusinessSegment.SPORTS_ACTIVITIES_AND_LEISURE]: ItemSportsIcon,
  [BusinessSegment.BEAUTY_AND_WELLNESS]: ItemBeautyIcon,
  [BusinessSegment.HEALTH_AND_MEDICINE]: ItemHealthIcon,
  [BusinessSegment.PUBLIC_AND_PROFESSIONAL_SERVICE]: ItemPublicIcon,
  [BusinessSegment.REPAIR_AND_MAINTENANCE]: ItemRepairIcon,
  [BusinessSegment.EDUCATION_AND_LEARNING]: ItemEducationIcon,
  [BusinessSegment.TAXI_AND_TRANSPORT]: ItemTransportIcon,
}

export const BUSINESS_SEGMENT_LABELS: Record<BusinessSegment, string> = {
  [BusinessSegment.FOOD_AND_DRINK]: 'portal_item_edit.segment.food',
  [BusinessSegment.SHOPPING_AND_RETAIL]: 'portal_item_edit.segment.shopping',
  [BusinessSegment.ARTS_CULTURE_AND_ENTERTAINMENT]:
    'portal_item_edit.segment.arts',
  [BusinessSegment.ACCOMMODATION_AND_TRAVEL]:
    'portal_item_edit.segment.accommodation',
  [BusinessSegment.SPORTS_ACTIVITIES_AND_LEISURE]:
    'portal_item_edit.segment.sports',
  [BusinessSegment.BEAUTY_AND_WELLNESS]: 'portal_item_edit.segment.beauty',
  [BusinessSegment.HEALTH_AND_MEDICINE]: 'portal_item_edit.segment.health',
  [BusinessSegment.PUBLIC_AND_PROFESSIONAL_SERVICE]:
    'portal_item_edit.segment.public',
  [BusinessSegment.REPAIR_AND_MAINTENANCE]: 'portal_item_edit.segment.repair',
  [BusinessSegment.EDUCATION_AND_LEARNING]:
    'portal_item_edit.segment.education',
  [BusinessSegment.TAXI_AND_TRANSPORT]: 'portal_item_edit.segment.transport',
}
