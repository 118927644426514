import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { format } from 'date-fns'

import {
  currentLocaleState,
  portalSettingState,
  publicPortalInfoState,
} from 'state/portalSettingStates'
import {
  DEFAULT_DATE_FORMAT,
  SUPPORT_REGIONS_PHONE_PREFIX_MAP,
} from '../commonConstants'
import { type LocalizedString } from 'types'
import { findCategory } from 'utils/categoryUtils'
import { type CaseCategory } from 'components/case/caseTypes'
import {
  type Category,
  type CategoryBasic,
} from 'components/category/categoryTypes'

type UsePortalSettingReturn = {
  formatDate: (dateString: string, showOnlyDate?: boolean) => string
  formatPhoneNumber: (input?: string) => string
  getLocalizedContent: (inputs?: LocalizedString[]) => string
  retrieveCategoryAndParentsName: (
    ids: string,
    categories: Category[] | CategoryBasic[],
  ) => string[]
  extractCaseCategoryIds: (inputCategory: CaseCategory) => string
  extractCaseCategoryNames: (inputCategory: CaseCategory) => string[]
  persistFilters: (key: string, value: unknown) => void
  resetFilters: () => void
}

const usePortalSetting = (): UsePortalSettingReturn => {
  const portalSetting = useRecoilValue(portalSettingState)
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const currentLocale = useRecoilValue(currentLocaleState)

  const formatDate = useCallback(
    (dateString: string, showOnlyDate?: boolean): string => {
      if (!dateString) {
        return ''
      }

      const rule =
        portalSetting?.dateFormat ||
        publicPortalInfo?.dateFormat ||
        DEFAULT_DATE_FORMAT

      const date = new Date(dateString)
      return format(date, showOnlyDate ? rule.split(' ')[0] : rule)
    },
    [portalSetting, publicPortalInfo],
  )

  const getLocalizedContent = useCallback(
    (inputs?: LocalizedString[]): string => {
      if (!inputs || inputs.length === 0) {
        return ''
      }

      const locale =
        currentLocale ||
        portalSetting?.defaultLanguage ||
        publicPortalInfo?.defaultLanguage

      const input = inputs.find((input) => input.language === locale)

      const fallbackLanguage =
        portalSetting?.defaultLanguage || publicPortalInfo?.defaultLanguage

      if (!input && fallbackLanguage) {
        const fallback = inputs.find(
          (input) => input.language === fallbackLanguage,
        )

        if (fallback?.content) {
          return fallback.content
        }

        return inputs[0].content
      }

      return input?.content || ''
    },

    [portalSetting, currentLocale, publicPortalInfo],
  )

  const formatPhoneNumber = useCallback(
    (phoneNumber?: string): string => {
      if (!phoneNumber) {
        return ''
      }

      const trimmedPhoneNumber = phoneNumber.replace(/\s+/g, '')

      if (trimmedPhoneNumber.startsWith('00')) {
        return trimmedPhoneNumber.replace(/^00/, '+')
      }

      if (
        !portalSetting?.mapConfiguration?.region ||
        trimmedPhoneNumber.startsWith('+')
      ) {
        return trimmedPhoneNumber
      }

      if (phoneNumber.startsWith('0')) {
        const prefix =
          SUPPORT_REGIONS_PHONE_PREFIX_MAP[
            portalSetting.mapConfiguration?.region
          ]
        return trimmedPhoneNumber.replace(/^0/, prefix)
      }

      return trimmedPhoneNumber
    },
    [portalSetting],
  )

  const extractCaseCategoryIds = (inputCategory: CaseCategory): string => {
    const ids: string[] = []

    const traverseCategory = (category: CaseCategory): void => {
      ids.unshift(category.id)
      if (category.parent) {
        traverseCategory(category.parent)
      }
    }

    traverseCategory(inputCategory)

    return ids.join('|')
  }

  const extractCaseCategoryNames = (inputCategory: CaseCategory): string[] => {
    const labels: string[] = []

    const traverseCategory = (category: CaseCategory): void => {
      labels.unshift(getLocalizedContent(category.names))
      if (category.parent) {
        traverseCategory(category.parent)
      }
    }

    traverseCategory(inputCategory)

    return labels
  }

  const retrieveCategoryAndParentsName = (
    ids: string,
    categories: Category[] | CategoryBasic[],
  ): string[] => {
    const caseCategories = ids.split('|')
    const labels = []

    if (caseCategories && categories) {
      for (const category of caseCategories) {
        const foundCategory = findCategory(category, categories)
        if (foundCategory) {
          labels.push(getLocalizedContent(foundCategory.names))
        }
      }
    }

    return labels
  }

  const persistFilters = useCallback(
    (key: string, value: unknown): void => {
      const portalId = portalSetting?.id || publicPortalInfo?.id
      if (portalId) {
        const filters = JSON.parse(localStorage.getItem('caseFilters') ?? '{}')
        const portalFilters = filters[portalId]
        localStorage.setItem(
          'caseFilters',
          JSON.stringify({
            ...filters,
            [portalId]: {
              ...portalFilters,
              [key]: value,
            },
          }),
        )
      }
    },
    [portalSetting],
  )

  const resetFilters = useCallback((): void => {
    const portalId = portalSetting?.id || publicPortalInfo?.id
    if (portalId) {
      localStorage.setItem(
        'caseFilters',
        JSON.stringify({
          [portalId]: {},
        }),
      )
    }
  }, [portalSetting, publicPortalInfo])

  const memoizedValue = useMemo(
    () => ({
      formatDate,
      getLocalizedContent,
      formatPhoneNumber,
      retrieveCategoryAndParentsName,
      extractCaseCategoryIds,
      extractCaseCategoryNames,
      persistFilters,
      resetFilters,
    }),
    [
      formatDate,
      getLocalizedContent,
      formatPhoneNumber,
      currentLocale,
      persistFilters,
      resetFilters,
    ],
  )

  return memoizedValue
}

export default usePortalSetting
